import { useToast } from "@finder/ui-kit"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { OptionCustomizationLocalizationsDTO, OptionCustomizationValuesDTOMapStringLocalizationsItemDTO } from "../../../../../generated/pdsapi"
import { LocalizationsUpdateDTO } from "../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { stringConverterGroup, useURLSearchParamState } from "../../../../../common/useURLSearchParamState"
import { browserLanguage } from "../../../../../common/global"
import { OptionCustomizationKey } from "../OptionCustomizationKey"
import { showErrorToast } from "../../../../../common/errorToastHelper"
import { ProductDetailsTab } from "../../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { WidgetWrapper } from "../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { TextSelector } from "../../../../../components/selectors/textselector/TextSelector"
import { conditionalValueLabelOf } from "../../../../../common/conditionalValueLabel"
import { DetailsLocalizationCard } from "../../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"

interface OptionCustomizationLocalizationTabProps {
    getLocalization: (props: OptionCustomizationKey & { languageTag: string }) => Promise<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO>
    updateDefaultValue: (props: OptionCustomizationKey & { requestBody: LocalizationsUpdateDTO }) => Promise<void>
    updateConditionalValue: (
        props: OptionCustomizationKey & {
            conditionalValueKey: string
            requestBody: LocalizationsUpdateDTO
        }
    ) => Promise<void>
}

export const OptionCustomizationLocalizationTab: FC<ProductDetailsTabConfigContentProps<OptionCustomizationKey> & OptionCustomizationLocalizationTabProps> = (
    props
) => {
    const { itemKey, getLocalization, updateDefaultValue, updateConditionalValue, urlPathParamsPrefix, reloadDataIndicator } = props

    const toastRef = useRef(useToast())
    const [conditionalValueKey, setConditionalValueKey] = useURLSearchParamState(
        `${urlPathParamsPrefix}.conditionalValueKey`,
        undefined,
        stringConverterGroup.optional
    )

    const [optionCustomizationLocalizations, setOptionCustomizationLocalizations] = useState<OptionCustomizationLocalizationsDTO | undefined>()

    const optionCustomizationLocalizationsPromise = useMemo(
        () =>
            getLocalization({
                ...itemKey,
                languageTag: browserLanguage,
            }),
        [getLocalization, itemKey, reloadDataIndicator]
    )

    useEffect(() => {
        const fetchData = async () => {
            try {
                setOptionCustomizationLocalizations(await optionCustomizationLocalizationsPromise)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchData()
    }, [optionCustomizationLocalizationsPromise])

    const getContentData = useCallback(
        () =>
            optionCustomizationLocalizationsPromise.then((optionCustomizationLocalizations) =>
                conditionalValueKey === undefined
                    ? optionCustomizationLocalizations.defaultValue
                    : optionCustomizationLocalizations.conditionalValues.find((conditionalValue) => conditionalValue.key === conditionalValueKey)!.value
            ),
        [optionCustomizationLocalizationsPromise, conditionalValueKey]
    )
    const updateData = useCallback(
        ({ key, type, update }: OptionCustomizationKey & { update: LocalizationsUpdateDTO }) =>
            conditionalValueKey === undefined
                ? updateDefaultValue({
                      key,
                      type,
                      requestBody: update,
                  })
                : updateConditionalValue({ key, type, conditionalValueKey, requestBody: update }),
        [conditionalValueKey, updateDefaultValue, updateConditionalValue]
    )

    return (
        <ProductDetailsTab>
            <WidgetWrapper label={"Condition"}>
                <TextSelector
                    label={undefined}
                    options={[
                        {
                            value: "",
                            label: "Default Value",
                        },
                        ...(optionCustomizationLocalizations?.conditionalValues.map((conditionalValue) => ({
                            value: conditionalValue.key,
                            label: conditionalValueLabelOf(conditionalValue),
                        })) ?? []),
                    ]}
                    value={conditionalValueKey ?? ""}
                    onChange={(value) => setConditionalValueKey(value ? value : undefined)}
                />
            </WidgetWrapper>
            <DetailsLocalizationCard {...props} label={"Localizations"} getData={getContentData} updateData={updateData} />
        </ProductDetailsTab>
    )
}
