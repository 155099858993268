/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationItemDTOPaintToSampleExteriorColorInternal,
    OptionCustomizationItemDTOPaintToSampleExteriorColorInternalFromJSONTyped,
    OptionCustomizationItemDTOPaintToSampleExteriorColorInternalToJSON,
} from './';

/**
 * @type OptionCustomizationItemDTOPaintToSampleExteriorColor
 * 
 * @export
 */
export type OptionCustomizationItemDTOPaintToSampleExteriorColor = { type: 'PaintToSampleExteriorColor' } & OptionCustomizationItemDTOPaintToSampleExteriorColorInternal;

export function OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSON(json: any): OptionCustomizationItemDTOPaintToSampleExteriorColor {
    return OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTOPaintToSampleExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'PaintToSampleExteriorColor':
            return {...OptionCustomizationItemDTOPaintToSampleExteriorColorInternalFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of OptionCustomizationItemDTOPaintToSampleExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationItemDTOPaintToSampleExteriorColorToJSON(value?: OptionCustomizationItemDTOPaintToSampleExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'PaintToSampleExteriorColor':
            return OptionCustomizationItemDTOPaintToSampleExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationItemDTOPaintToSampleExteriorColor exists with 'type=${value['type']}'`);
    }
}

