/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    OldCustomizationLinksDTO,
    OldCustomizationLinksDTOFromJSON,
    OldCustomizationLinksDTOToJSON,
    OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO,
    OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTOFromJSON,
    OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTOToJSON,
    OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO,
    OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTOFromJSON,
    OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTOToJSON,
    OptionCustomizationLocalizationsDTO,
    OptionCustomizationLocalizationsDTOFromJSON,
    OptionCustomizationLocalizationsDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface DeleteRoofColorCustomizationRequest {
    key: string;
}

export interface GetAttributesOfRoofColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfRoofColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfRoofColorCustomizationRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfRoofColorCustomizationRequest {
    key: string;
}

export interface GetLocalizationsOfNameOfRoofColorCustomizationRequest {
    key: string;
}

export interface GetVehicleImagesOfRoofColorCustomizationRequest {
    key: string;
    limit?: number;
}

export interface UpdateAttributesOfRoofColorCustomizationRequest {
    key: string;
    oldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO: OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO;
}

export interface UpdateDescriptionOfDefaultValueOfRoofColorCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfDefaultValueOfRoofColorCustomizationRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRequest {
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class RoofColorCustomizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a RoofColorCustomization by a given key
     */
    async deleteRoofColorCustomizationRaw(requestParameters: DeleteRoofColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteRoofColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a RoofColorCustomization by a given key
     */
    async deleteRoofColorCustomization(requestParameters: DeleteRoofColorCustomizationRequest): Promise<void> {
        await this.deleteRoofColorCustomizationRaw(requestParameters);
    }

    /**
     * Get attributes of RoofColorCustomization with given key
     */
    async getAttributesOfRoofColorCustomizationRaw(requestParameters: GetAttributesOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfRoofColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of RoofColorCustomization with given key
     */
    async getAttributesOfRoofColorCustomization(requestParameters: GetAttributesOfRoofColorCustomizationRequest): Promise<OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO> {
        const response = await this.getAttributesOfRoofColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of RoofColorCustomization with given key
     */
    async getBaseInformationOfRoofColorCustomizationRaw(requestParameters: GetBaseInformationOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfRoofColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of RoofColorCustomization with given key
     */
    async getBaseInformationOfRoofColorCustomization(requestParameters: GetBaseInformationOfRoofColorCustomizationRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfRoofColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of RoofColorCustomization with given key
     */
    async getLinksOfRoofColorCustomizationRaw(requestParameters: GetLinksOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<OldCustomizationLinksDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfRoofColorCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OldCustomizationLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of RoofColorCustomization with given key
     */
    async getLinksOfRoofColorCustomization(requestParameters: GetLinksOfRoofColorCustomizationRequest): Promise<OldCustomizationLinksDTO> {
        const response = await this.getLinksOfRoofColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of RoofColorCustomization with given key
     */
    async getLocalizationsOfDescriptionOfRoofColorCustomizationRaw(requestParameters: GetLocalizationsOfDescriptionOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfDescriptionOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/description/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfDescription of RoofColorCustomization with given key
     */
    async getLocalizationsOfDescriptionOfRoofColorCustomization(requestParameters: GetLocalizationsOfDescriptionOfRoofColorCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfDescriptionOfRoofColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of RoofColorCustomization with given key
     */
    async getLocalizationsOfNameOfRoofColorCustomizationRaw(requestParameters: GetLocalizationsOfNameOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLocalizationsDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLocalizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfName of RoofColorCustomization with given key
     */
    async getLocalizationsOfNameOfRoofColorCustomization(requestParameters: GetLocalizationsOfNameOfRoofColorCustomizationRequest): Promise<OptionCustomizationLocalizationsDTO> {
        const response = await this.getLocalizationsOfNameOfRoofColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get vehicleImages of RoofColorCustomization with given key
     */
    async getVehicleImagesOfRoofColorCustomizationRaw(requestParameters: GetVehicleImagesOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getVehicleImagesOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/vehicle-images`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Get vehicleImages of RoofColorCustomization with given key
     */
    async getVehicleImagesOfRoofColorCustomization(requestParameters: GetVehicleImagesOfRoofColorCustomizationRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesOfRoofColorCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing RoofColorCustomization
     */
    async updateAttributesOfRoofColorCustomizationRaw(requestParameters: UpdateAttributesOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfRoofColorCustomization.');
        }

        if (requestParameters.oldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO === null || requestParameters.oldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO === undefined) {
            throw new runtime.RequiredError('oldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO','Required parameter requestParameters.oldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO was null or undefined when calling updateAttributesOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTOToJSON(requestParameters.oldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing RoofColorCustomization
     */
    async updateAttributesOfRoofColorCustomization(requestParameters: UpdateAttributesOfRoofColorCustomizationRequest): Promise<void> {
        await this.updateAttributesOfRoofColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing RoofColorCustomization
     */
    async updateDescriptionOfDefaultValueOfRoofColorCustomizationRaw(requestParameters: UpdateDescriptionOfDefaultValueOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfDefaultValueOfRoofColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfDefaultValueOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/default-value/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing RoofColorCustomization
     */
    async updateDescriptionOfDefaultValueOfRoofColorCustomization(requestParameters: UpdateDescriptionOfDefaultValueOfRoofColorCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfDefaultValueOfRoofColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing RoofColorCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRaw(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing RoofColorCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfDefaultValue of an existing RoofColorCustomization
     */
    async updateNameOfDefaultValueOfRoofColorCustomizationRaw(requestParameters: UpdateNameOfDefaultValueOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfDefaultValueOfRoofColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfDefaultValueOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/default-value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfDefaultValue of an existing RoofColorCustomization
     */
    async updateNameOfDefaultValueOfRoofColorCustomization(requestParameters: UpdateNameOfDefaultValueOfRoofColorCustomizationRequest): Promise<void> {
        await this.updateNameOfDefaultValueOfRoofColorCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing RoofColorCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRaw(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/roof-color-customizations/details/{key}/conditional-values/{conditionalValueKey}/value/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing RoofColorCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomization(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRequest): Promise<void> {
        await this.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfRoofColorCustomizationRaw(requestParameters);
    }

}
