import { FC, useCallback } from "react"
import { VehicleStateSearchResult } from "../../../../../../generated/pdsapi"
import { WidgetWrapper } from "../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { LoadableVehicleImagesCardContent } from "../../../../../../components/loadablevehicleimagescardcontent/LoadableVehicleImagesCardContent"
import { OptionCustomizationKey } from "../../OptionCustomizationKey"

interface DetailsVehicleImagesCardProps {
    itemKey: OptionCustomizationKey
    load: (props: OptionCustomizationKey) => Promise<VehicleStateSearchResult[]>
    shouldLoad?: boolean
    isInitiallyExpanded?: boolean
}

export const VehicleImagesCard: FC<DetailsVehicleImagesCardProps> = ({ itemKey, load, shouldLoad, isInitiallyExpanded }) => {
    const loadVehicleImages = useCallback(() => load(itemKey), [load, itemKey])
    return (
        <WidgetWrapper label={"Vehicle Images"}>
            <LoadableVehicleImagesCardContent load={loadVehicleImages} shouldLoad={shouldLoad} isInitiallyExpanded={isInitiallyExpanded} />
        </WidgetWrapper>
    )
}
