/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    GlobalOrderTypeTechnicalDataAttributeDTO,
    GlobalOrderTypeTechnicalDataAttributeDTOFromJSON,
    GlobalOrderTypeTechnicalDataAttributeDTOToJSON,
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO,
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOFromJSON,
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOToJSON,
    OrderTypeTechnicalDataAttributeUpdateDTO,
    OrderTypeTechnicalDataAttributeUpdateDTOFromJSON,
    OrderTypeTechnicalDataAttributeUpdateDTOToJSON,
    OrderTypeTechnicalDataRelatedDataDTO,
    OrderTypeTechnicalDataRelatedDataDTOFromJSON,
    OrderTypeTechnicalDataRelatedDataDTOToJSON,
    PdsMarketplace,
    PdsMarketplaceFromJSON,
    PdsMarketplaceToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    TechnicalDataAttribute,
    TechnicalDataAttributeFromJSON,
    TechnicalDataAttributeToJSON,
} from '../models';

export interface GetGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest {
    key: string;
    technicalDataAttribute: TechnicalDataAttribute;
}

export interface GetRelatedDataOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface GetmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest {
    key: string;
    marketplace: PdsMarketplace;
    technicalDataAttribute: TechnicalDataAttribute;
}

export interface UpdateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest {
    key: string;
    technicalDataAttribute: TechnicalDataAttribute;
    orderTypeTechnicalDataAttributeUpdateDTO: OrderTypeTechnicalDataAttributeUpdateDTO;
}

export interface UpdatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest {
    key: string;
    marketplace: PdsMarketplace;
    technicalDataAttribute: TechnicalDataAttribute;
    orderTypeTechnicalDataAttributeUpdateDTO: OrderTypeTechnicalDataAttributeUpdateDTO;
}

/**
 * 
 */
export class OrderTypeTechnicalDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get globalOfTechnicalDataAttributesByTechnicalDataAttribute of OrderType with given key
     */
    async getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters: GetGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest): Promise<runtime.ApiResponse<GlobalOrderTypeTechnicalDataAttributeDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        if (requestParameters.technicalDataAttribute === null || requestParameters.technicalDataAttribute === undefined) {
            throw new runtime.RequiredError('technicalDataAttribute','Required parameter requestParameters.technicalDataAttribute was null or undefined when calling getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/technical-data-attributes/{technicalDataAttribute}/global`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"technicalDataAttribute"}}`, encodeURIComponent(String(requestParameters.technicalDataAttribute))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalOrderTypeTechnicalDataAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get globalOfTechnicalDataAttributesByTechnicalDataAttribute of OrderType with given key
     */
    async getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType(requestParameters: GetGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest): Promise<GlobalOrderTypeTechnicalDataAttributeDTO> {
        const response = await this.getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get relatedData of OrderType with given key
     */
    async getRelatedDataOfOrderTypeRaw(requestParameters: GetRelatedDataOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeTechnicalDataRelatedDataDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getRelatedDataOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getRelatedDataOfOrderType.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/related-data`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeTechnicalDataRelatedDataDTOFromJSON(jsonValue));
    }

    /**
     * Get relatedData of OrderType with given key
     */
    async getRelatedDataOfOrderType(requestParameters: GetRelatedDataOfOrderTypeRequest): Promise<OrderTypeTechnicalDataRelatedDataDTO> {
        const response = await this.getRelatedDataOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get {marketplace}OfSpecificOfTechnicalDataAttributesByTechnicalDataAttribute of OrderType with given key
     */
    async getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters: GetmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest): Promise<runtime.ApiResponse<MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        if (requestParameters.marketplace === null || requestParameters.marketplace === undefined) {
            throw new runtime.RequiredError('marketplace','Required parameter requestParameters.marketplace was null or undefined when calling getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        if (requestParameters.technicalDataAttribute === null || requestParameters.technicalDataAttribute === undefined) {
            throw new runtime.RequiredError('technicalDataAttribute','Required parameter requestParameters.technicalDataAttribute was null or undefined when calling getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/technical-data-attributes/{technicalDataAttribute}/specific/{marketplace}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"marketplace"}}`, encodeURIComponent(String(requestParameters.marketplace))).replace(`{${"technicalDataAttribute"}}`, encodeURIComponent(String(requestParameters.technicalDataAttribute))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get {marketplace}OfSpecificOfTechnicalDataAttributesByTechnicalDataAttribute of OrderType with given key
     */
    async getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType(requestParameters: GetmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest): Promise<MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO> {
        const response = await this.getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates globalOfTechnicalDataAttributesByTechnicalDataAttribute of an existing OrderType
     */
    async updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters: UpdateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        if (requestParameters.technicalDataAttribute === null || requestParameters.technicalDataAttribute === undefined) {
            throw new runtime.RequiredError('technicalDataAttribute','Required parameter requestParameters.technicalDataAttribute was null or undefined when calling updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        if (requestParameters.orderTypeTechnicalDataAttributeUpdateDTO === null || requestParameters.orderTypeTechnicalDataAttributeUpdateDTO === undefined) {
            throw new runtime.RequiredError('orderTypeTechnicalDataAttributeUpdateDTO','Required parameter requestParameters.orderTypeTechnicalDataAttributeUpdateDTO was null or undefined when calling updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/technical-data-attributes/{technicalDataAttribute}/global`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"technicalDataAttribute"}}`, encodeURIComponent(String(requestParameters.technicalDataAttribute))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeTechnicalDataAttributeUpdateDTOToJSON(requestParameters.orderTypeTechnicalDataAttributeUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates globalOfTechnicalDataAttributesByTechnicalDataAttribute of an existing OrderType
     */
    async updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType(requestParameters: UpdateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest): Promise<void> {
        await this.updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters);
    }

    /**
     * Updates {marketplace}OfSpecificOfTechnicalDataAttributesByTechnicalDataAttribute of an existing OrderType
     */
    async updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters: UpdatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        if (requestParameters.marketplace === null || requestParameters.marketplace === undefined) {
            throw new runtime.RequiredError('marketplace','Required parameter requestParameters.marketplace was null or undefined when calling updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        if (requestParameters.technicalDataAttribute === null || requestParameters.technicalDataAttribute === undefined) {
            throw new runtime.RequiredError('technicalDataAttribute','Required parameter requestParameters.technicalDataAttribute was null or undefined when calling updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        if (requestParameters.orderTypeTechnicalDataAttributeUpdateDTO === null || requestParameters.orderTypeTechnicalDataAttributeUpdateDTO === undefined) {
            throw new runtime.RequiredError('orderTypeTechnicalDataAttributeUpdateDTO','Required parameter requestParameters.orderTypeTechnicalDataAttributeUpdateDTO was null or undefined when calling updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/technical-data-attributes/{technicalDataAttribute}/specific/{marketplace}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"marketplace"}}`, encodeURIComponent(String(requestParameters.marketplace))).replace(`{${"technicalDataAttribute"}}`, encodeURIComponent(String(requestParameters.technicalDataAttribute))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeTechnicalDataAttributeUpdateDTOToJSON(requestParameters.orderTypeTechnicalDataAttributeUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates {marketplace}OfSpecificOfTechnicalDataAttributesByTechnicalDataAttribute of an existing OrderType
     */
    async updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType(requestParameters: UpdatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest): Promise<void> {
        await this.updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters);
    }

}
