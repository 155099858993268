import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ActiveTag } from "../../../../../components/filteredpagedtable/filtertags/activetag/ActiveTag"
import { Tag } from "../../../../../components/tag/Tag"
import { EquipmentTagFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { EquipmentTagFilter } from "../../../../../pdsapi"
import { findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const EquipmentTagListFilterTagsConfig: TableFilterTagsConfig<EquipmentTagFilter, EquipmentTagFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.categories?.forEach((category) => {
        filterTags.push(
            <Tag
                key={`filterTag_category_${category}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newCategories = filter.categories?.filter((key) => category !== key)
                        return {
                            ...filter,
                            categories: newCategories?.length !== 0 ? newCategories : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.categories, category)?.label ?? category}
            </Tag>
        )
    })

    if (filter.active !== undefined) {
        filterTags.push(
            <ActiveTag
                key={"active_tag"}
                active={filter.active}
                removeActiveFlag={() =>
                    setFilter((findParams) => ({
                        ...findParams,
                        active: undefined,
                    }))
                }
            />
        )
    }

    return filterTags
}
