import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { OptionImage } from "../../../../../components/optionimage/OptionImage"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { NamedInteriorColorTile } from "../../../../../components/tiles/namedinteriorcolortile/NamedInteriorColorTile"
import { InteriorItemDTO, InteriorSortField } from "../../../../../generated/pdsapi"

type InteriorCatalogueColumnBuilder = ColumnBuilder<InteriorItemDTO, InteriorSortField>

export const InteriorCatalogueColumnsConfig: TableColumnsConfig<InteriorItemDTO, InteriorSortField> = () => {
    const imageColumn: InteriorCatalogueColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (interiorDetails) => <OptionImage imageKey={interiorDetails.imageKey} sizes={"100px"} />,
    }

    const orderTypeCodesColumn: InteriorCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Order Types",
        },
        buildCellContent: (interiorDetails) => interiorDetails.orderTypeCodes.join(", "),
    }

    const modelYearColumn: InteriorCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Model Year",
        },
        buildCellContent: (interiorDetails) => interiorDetails.modelYear.toString(),
    }

    const optionCodeColumn: InteriorCatalogueColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (interiorDetails) => interiorDetails.optionCode,
    }

    const nameColumn: InteriorCatalogueColumnBuilder = {
        grow: 8,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (interiorDetails) =>
            interiorDetails.color ? (
                <NamedInteriorColorTile
                    label={interiorDetails.name ?? "-"}
                    seatColor={interiorDetails.color.seatColorHexCode}
                    seatStitchingColor={interiorDetails.color.seatStitchingColorHexCode}
                    cockpitColor={interiorDetails.color.cockpitColorHexCode}
                    cockpitStitchingColor={interiorDetails.color.cockpitStitchingColorHexCode}
                />
            ) : (
                (interiorDetails.name ?? "-")
            ),
    }

    const interiorMaterialColumn: InteriorCatalogueColumnBuilder = {
        grow: 5,
        headerCellProps: {
            content: "Material",
        },
        buildCellContent: (interiorDetails) => interiorDetails.materialName ?? "-",
    }

    const colorTagColumn: InteriorCatalogueColumnBuilder = {
        grow: 5,
        headerCellProps: {
            content: "Color Tag",
        },
        buildCellContent: (interiorDetails) =>
            interiorDetails.colorTag ? <NamedColorTile hexCode={interiorDetails.colorTag.hexCode} label={interiorDetails.colorTag.label} /> : "-",
    }

    const modelGenerationColumn: InteriorCatalogueColumnBuilder = {
        grow: 5,
        headerCellProps: {
            content: "Model Generation",
        },
        buildCellContent: (interiorDetails) => interiorDetails.modelGenerationName ?? "-",
    }

    return [imageColumn, orderTypeCodesColumn, modelYearColumn, optionCodeColumn, nameColumn, interiorMaterialColumn, colorTagColumn, modelGenerationColumn]
}
