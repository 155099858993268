import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { DerivativeCategoryFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { DerivativeCategoryFilter } from "../../../../../pdsapi"
import { findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const DerivativeCategoryListFilterTagsConfig: TableFilterTagsConfig<DerivativeCategoryFilter, DerivativeCategoryFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.modelSeriesKeys?.forEach((modelSeriesKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelSeriesKey_${modelSeriesKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelSeriesKeys = filter.modelSeriesKeys?.filter((key) => modelSeriesKey !== key)
                        return {
                            ...filter,
                            modelSeriesKeys: newModelSeriesKeys?.length !== 0 ? newModelSeriesKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelSeries, modelSeriesKey)?.label ?? modelSeriesKey}
            </Tag>
        )
    })

    return filterTags
}
