import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { EquipmentCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { EquipmentCustomizationFilter } from "../../../../../pdsapi"
import { JSX } from "react"

export const EquipmentCustomizationListFilterTagsConfig: TableFilterTagsConfig<EquipmentCustomizationFilter, EquipmentCustomizationFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {filterOptions?.modelGenerations.find((option) => option.key === modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.equipmentTagKeys?.forEach((equipmentTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_equipmentCTagKey_${equipmentTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newEquipmentTagKeys = filter.equipmentTagKeys?.filter((key) => equipmentTagKey !== key)
                        return {
                            ...filter,
                            equipmentTagKeys: newEquipmentTagKeys?.length !== 0 ? newEquipmentTagKeys : undefined,
                        }
                    })
                }
            >
                {filterOptions?.categorizedEquipmentTags.flatMap((category) => category.options).find((option) => option.key === equipmentTagKey)?.label ??
                    equipmentTagKey}
            </Tag>
        )
    })

    return filterTags
}
