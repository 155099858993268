/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultiSelectDTOString,
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueDTO,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueDTOFromJSON,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueDTOToJSON,
    OptionDTOString,
    OptionDTOStringFromJSON,
    OptionDTOStringFromJSONTyped,
    OptionDTOStringToJSON,
    RoofColorCustomizationAttributesValueDTO,
    RoofColorCustomizationAttributesValueDTOFromJSON,
    RoofColorCustomizationAttributesValueDTOFromJSONTyped,
    RoofColorCustomizationAttributesValueDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO
 */
export interface OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO {
    /**
     * 
     * @type {string}
     * @memberof OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO
     */
    optionCode: string;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {Array<OptionDTOString>}
     * @memberof OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO
     */
    derivatives: Array<OptionDTOString>;
    /**
     * 
     * @type {boolean}
     * @memberof OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {RoofColorCustomizationAttributesValueDTO}
     * @memberof OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO
     */
    defaultValue: RoofColorCustomizationAttributesValueDTO;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueDTO>}
     * @memberof OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueDTO>;
}

export function OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTOFromJSON(json: any): OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO {
    return OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTOFromJSONTyped(json, false);
}

export function OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'derivatives': ((json['derivatives'] as Array<any>).map(OptionDTOStringFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': RoofColorCustomizationAttributesValueDTOFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueDTOFromJSON)),
    };
}

export function OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTOToJSON(value?: OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerations': MultiSelectDTOStringToJSON(value.modelGenerations),
        'derivatives': ((value.derivatives as Array<any>).map(OptionDTOStringToJSON)),
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': RoofColorCustomizationAttributesValueDTOToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueDTOToJSON)),
    };
}


