import { TableColumnsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../../../components/layouts/table/Table"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { getSortIndexColumnBuilder } from "../../../../../../../components/pagedtable/columns/columnBuilder"
import { EquipmentSortField, OrderTypeEquipmentItemDTO } from "../../../../../../../generated/pdsapi"

type EquipmentItemCardColumnBuilder = ColumnBuilder<OrderTypeEquipmentItemDTO, EquipmentSortField>

export const OrderTypeEquipmentCardColumnsConfig: TableColumnsConfig<OrderTypeEquipmentItemDTO, EquipmentSortField> = ({ sorting, setSorting }) => {
    const previewColumn: EquipmentItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (equipment) => <OptionImage imageKey={equipment.imageKey} sizes={"100px"} />,
    }

    const sortIndexColumn: EquipmentItemCardColumnBuilder = getSortIndexColumnBuilder((equipment) => equipment.sortIndex, {
        field: EquipmentSortField.SORT_INDEX,
        value: sorting,
        setValue: setSorting,
    })

    const optionCodeColumn: EquipmentItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (equipment) => equipment.optionCode,
    }

    const optionFamilyColumn: EquipmentItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Family",
        },
        buildCellContent: (equipment) => equipment.family ?? "-",
    }

    const nameColumn: EquipmentItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (equipment) => equipment.name ?? "-",
    }

    const equipmentCategoryColumn: EquipmentItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Category",
        },
        buildCellContent: (equipment) => equipment.categoryName ?? "-",
    }

    const tagsColumn: EquipmentItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Tags",
        },
        buildCellContent: (equipment) => equipment.tagNames.join(", "),
    }

    return [previewColumn, sortIndexColumn, optionCodeColumn, optionFamilyColumn, nameColumn, equipmentCategoryColumn, tagsColumn]
}
