import { rowGroupsSpacing } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    OldOptionCustomizationAttributesDTOPaintToSampleExteriorColorCustomizationAttributesValueDTO,
    OldOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO,
} from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import {
    OptionCustomizationDetailsAttributesCardContent,
    OptionCustomizationValueContent,
} from "../../../../../../components/optioncustomization/OptionCustomizationDetailsAttributesCardContent"

type AttributesDTO = OldOptionCustomizationAttributesDTOPaintToSampleExteriorColorCustomizationAttributesValueDTO
type Value = AttributesDTO["defaultValue"]
type AttributesUpdateDTO = OldOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO
type ValueUpdate = AttributesUpdateDTO["defaultValue"]

export const PaintToSampleExteriorColorCustomizationDetailsAttributesCardContent: FC<ProductDetailsCardContentProps<AttributesDTO, AttributesUpdateDTO>> = (
    props
) => (
    <OptionCustomizationDetailsAttributesCardContent
        {...props}
        convertValueToValueUpdate={convertValueToValueUpdate}
        CustomizationValueContent={CustomizationValueContent}
    />
)

const convertValueToValueUpdate = (value: Value): ValueUpdate => ({
    name: value.name,
    description: value.description,
})

const CustomizationValueContent: OptionCustomizationValueContent<Value, ValueUpdate> = ({ getValue, property, formDisplayData }) => {
    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={property.name}
                getViewProps={(data) => ({
                    value: getValue(data).name,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).name,
                    optional: true,
                })}
            />
            <DetailsDefaultLocalizationsFormRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={property.description}
                getViewProps={(data) => ({
                    value: getValue(data).description,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).description,
                    optional: true,
                })}
            />
        </SpacedItems>
    )
}
