import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO } from "../../../../generated/pdsapi"
import { optionCustomizationApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { VehicleImagesCreateCard } from "../../../../viewtemplates/create/cards/vehicleimagescard/VehicleImagesCreateCard"
import { OptionCustomizationCreateAttributesCard } from "./attributes/OptionCustomizationCreateAttributesCard"
import { optionCustomizationTypeToEnum } from "../optionCustomizationTypes"
import { getEditData } from "../../../../components/formelements/FormDisplayData"

export const OptionCustomizationProductCreateContainerConfig: ProductCreateContainerConfig<
    OptionCustomizationCreateRequestDTO,
    OptionCustomizationCreateOptionsDTO
> = {
    headline: "Create new Option Customization",

    create: (item) =>
        optionCustomizationApi.create.create({
            optionCustomizationCreateRequestDTO: item,
        }),
    getCreateOptions: optionCustomizationApi.create.getCreateOptions,

    Content: (props) => {
        const type = getEditData(props.formDisplayData)?.watch("type")
        return (
            <DefaultCardViewContentWrapper>
                <OptionCustomizationCreateAttributesCard {...props} />
                {type && (
                    <VehicleImagesCreateCard
                        formDisplayData={props.formDisplayData}
                        getVehicleImages={(args) =>
                            optionCustomizationApi.create.getVehicleImages({
                                ...args,
                                type: optionCustomizationTypeToEnum(type),
                            })
                        }
                    />
                )}
            </DefaultCardViewContentWrapper>
        )
    },
}

export const OptionCustomizationCreateContainer = getProductCreateContainer(OptionCustomizationProductCreateContainerConfig)
