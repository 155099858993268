/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationCreateRequestDTOExteriorColor,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColor,
    OptionCustomizationCreateRequestDTOExteriorColorFromJSONTyped,
    OptionCustomizationCreateRequestDTOExteriorColorToJSON,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorToJSON,
} from './';

/**
 * @type OptionCustomizationCreateRequestDTO
 * 
 * @export
 */
export type OptionCustomizationCreateRequestDTO = { type: 'ExteriorColor' } & OptionCustomizationCreateRequestDTOExteriorColor | { type: 'PaintToSampleExteriorColor' } & OptionCustomizationCreateRequestDTOPaintToSampleExteriorColor;

export function OptionCustomizationCreateRequestDTOFromJSON(json: any): OptionCustomizationCreateRequestDTO {
    return OptionCustomizationCreateRequestDTOFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionCustomizationCreateRequestDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'PaintToSampleExteriorColor':
            return {...OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationCreateRequestDTOToJSON(value?: OptionCustomizationCreateRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionCustomizationCreateRequestDTOExteriorColorToJSON(value);
        case 'PaintToSampleExteriorColor':
            return OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTO exists with 'type=${value['type']}'`);
    }
}

