/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OptionSortField {
    SORT_INDEX = 'SORT_INDEX'
}

export function OptionSortFieldFromJSON(json: any): OptionSortField {
    return OptionSortFieldFromJSONTyped(json, false);
}

export function OptionSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionSortField {
    return json as OptionSortField;
}

export function OptionSortFieldToJSON(value?: OptionSortField | null): any {
    return value as any;
}

