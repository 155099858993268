import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { ExteriorColorTagItemDTO, OptionTagSortField } from "../../../../../generated/pdsapi"
import { activeColumn } from "../../../../../viewtemplates/list/columns/activeColumn"

type ExteriorColorTagColumnBuilder = ColumnBuilder<ExteriorColorTagItemDTO, OptionTagSortField>

export const ExteriorColorTagListColumnsConfig: TableColumnsConfig<ExteriorColorTagItemDTO, OptionTagSortField> = ({ sorting, setSorting }) => {
    const sortIndexColumn: ExteriorColorTagColumnBuilder = {
        width: 40,
        headerCellProps: {
            content: "#",
            sorting: {
                field: OptionTagSortField.SORT_INDEX,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (exteriorColorTag) => exteriorColorTag.sortIndex.toString(),
    }
    const nameColumn: ExteriorColorTagColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (exteriorColorTag) => exteriorColorTag.name,
    }
    const hexCodeColumn: ExteriorColorTagColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Hex Code",
        },
        buildCellContent: (exteriorColorTag) => <NamedColorTile hexCode={exteriorColorTag.hexCode} label={exteriorColorTag.hexCode} />,
    }

    return [sortIndexColumn, nameColumn, hexCodeColumn, activeColumn]
}
