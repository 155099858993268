import { roofColorRoutes } from "../../../../../../common/routes"
import { getObjectURLParamConverter, stringConverterGroup } from "../../../../../../common/useURLSearchParamState"
import { OrderTypeRoofColorFilterOptionsDTO, OrderTypeRoofColorItemDTO, RoofColorSortField } from "../../../../../../generated/pdsapi"
import { orderTypeApi, OrderTypeRoofColorsFilter } from "../../../../../../pdsapi"
import { OrderTypeOptionsCardProps } from "../OrderTypeOptionsCard"
import { OrderTypeRoofColorsCardColumnsConfig } from "./columns/OrderTypeRoofColorsCardColumns"
import { OrderTypeRoofColorsCardFilter } from "./filter/OrderTypeRoofColorsCardFilter"
import { OrderTypeRoofColorsCardFilterTagsConfig } from "./filter/OrderTypeRoofColorsCardFilterTagsConfig"

export const orderTypeRoofColorsCardConfig: OrderTypeOptionsCardProps<
    OrderTypeRoofColorItemDTO,
    OrderTypeRoofColorsFilter,
    OrderTypeRoofColorFilterOptionsDTO,
    RoofColorSortField
> = {
    label: "RoofColor",

    getCreateRoute: roofColorRoutes.create,
    getDetailsRoute: roofColorRoutes.details,
    getOptions: orderTypeApi.details.options.getRoofColors,
    getFilterOptions: orderTypeApi.details.options.getRoofColorFilterOptions,

    defaultSortField: RoofColorSortField.SORT_INDEX,
    sortFieldEnum: RoofColorSortField,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeRoofColorsFilter>({
        tagKeys: stringConverterGroup.optionalArray,
    }),

    columnsConfig: OrderTypeRoofColorsCardColumnsConfig,
    FilterComponent: OrderTypeRoofColorsCardFilter,
    filterTagsConfig: OrderTypeRoofColorsCardFilterTagsConfig,

    isInitiallyExpanded: false,
}
