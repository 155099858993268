import { FunctionComponent } from "react"
import { TableFilterProps } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { OrderTypeOptionFilterOptionsDTO, OrderTypeOptionTypeDTO } from "../../../../../../../generated/pdsapi"
import { MultiSelect } from "@finder/ui-kit"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { OrderTypeOptionFilter } from "../../../../../../../pdsapi"
import { ColumnGroupWrapper } from "../../../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { ColumnGroupsWrapper } from "../../../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"

export const OrderTypeOptionItemsCardFilter: FunctionComponent<TableFilterProps<OrderTypeOptionFilter, OrderTypeOptionFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const typeFilter = (
        <MultiSelect
            label={"Types"}
            placeholder={"All"}
            clearable={true}
            searchable={true}
            options={filterOptions?.types?.map(toOption)}
            value={filter.types ?? []}
            onChange={(types) =>
                setFilter((filter) => ({
                    ...filter,
                    types: types.length === 0 ? undefined : (types as OrderTypeOptionTypeDTO[]),
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Type"}>{typeFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}
