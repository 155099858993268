/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationCreateRequestDTOExteriorColorInternal,
    OptionCustomizationCreateRequestDTOExteriorColorInternalFromJSONTyped,
    OptionCustomizationCreateRequestDTOExteriorColorInternalToJSON,
} from './';

/**
 * @type OptionCustomizationCreateRequestDTOExteriorColor
 * 
 * @export
 */
export type OptionCustomizationCreateRequestDTOExteriorColor = { type: 'ExteriorColor' } & OptionCustomizationCreateRequestDTOExteriorColorInternal;

export function OptionCustomizationCreateRequestDTOExteriorColorFromJSON(json: any): OptionCustomizationCreateRequestDTOExteriorColor {
    return OptionCustomizationCreateRequestDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTOExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionCustomizationCreateRequestDTOExteriorColorInternalFromJSONTyped(json, true), type: 'ExteriorColor'};
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTOExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationCreateRequestDTOExteriorColorToJSON(value?: OptionCustomizationCreateRequestDTOExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionCustomizationCreateRequestDTOExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTOExteriorColor exists with 'type=${value['type']}'`);
    }
}

