/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionDTOBaseLocalization,
    OptionDTOBaseLocalizationFromJSON,
    OptionDTOBaseLocalizationFromJSONTyped,
    OptionDTOBaseLocalizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaintToSampleExteriorColorOptionDTO
 */
export interface PaintToSampleExteriorColorOptionDTO {
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    optionCode: string;
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    optionType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    imageKeys: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    sortIndex: number;
    /**
     * 
     * @type {OptionDTOBaseLocalization}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    name: OptionDTOBaseLocalization;
    /**
     * 
     * @type {OptionDTOBaseLocalization}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    description: OptionDTOBaseLocalization;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    zOrderOptionCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    paintToSampleEquipmentOptionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaintToSampleExteriorColorOptionDTO
     */
    paintToSamplePlusEquipmentOptionCode?: string;
}

export function PaintToSampleExteriorColorOptionDTOFromJSON(json: any): PaintToSampleExteriorColorOptionDTO {
    return PaintToSampleExteriorColorOptionDTOFromJSONTyped(json, false);
}

export function PaintToSampleExteriorColorOptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaintToSampleExteriorColorOptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'optionType': !exists(json, 'optionType') ? undefined : json['optionType'],
        'imageKeys': json['imageKeys'],
        'sortIndex': json['sortIndex'],
        'name': OptionDTOBaseLocalizationFromJSON(json['name']),
        'description': OptionDTOBaseLocalizationFromJSON(json['description']),
        'zOrderOptionCodes': !exists(json, 'zOrderOptionCodes') ? undefined : json['zOrderOptionCodes'],
        'paintToSampleEquipmentOptionCode': !exists(json, 'paintToSampleEquipmentOptionCode') ? undefined : json['paintToSampleEquipmentOptionCode'],
        'paintToSamplePlusEquipmentOptionCode': !exists(json, 'paintToSamplePlusEquipmentOptionCode') ? undefined : json['paintToSamplePlusEquipmentOptionCode'],
    };
}

export function PaintToSampleExteriorColorOptionDTOToJSON(value?: PaintToSampleExteriorColorOptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'optionType': value.optionType,
        'imageKeys': value.imageKeys,
        'sortIndex': value.sortIndex,
        'name': OptionDTOBaseLocalizationToJSON(value.name),
        'description': OptionDTOBaseLocalizationToJSON(value.description),
        'zOrderOptionCodes': value.zOrderOptionCodes,
        'paintToSampleEquipmentOptionCode': value.paintToSampleEquipmentOptionCode,
        'paintToSamplePlusEquipmentOptionCode': value.paintToSamplePlusEquipmentOptionCode,
    };
}


