/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationItemDTOExteriorColorInternal,
    OptionCustomizationItemDTOExteriorColorInternalFromJSONTyped,
    OptionCustomizationItemDTOExteriorColorInternalToJSON,
} from './';

/**
 * @type OptionCustomizationItemDTOExteriorColor
 * 
 * @export
 */
export type OptionCustomizationItemDTOExteriorColor = { type: 'ExteriorColor' } & OptionCustomizationItemDTOExteriorColorInternal;

export function OptionCustomizationItemDTOExteriorColorFromJSON(json: any): OptionCustomizationItemDTOExteriorColor {
    return OptionCustomizationItemDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTOExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionCustomizationItemDTOExteriorColorInternalFromJSONTyped(json, true), type: 'ExteriorColor'};
        default:
            throw new Error(`No variant of OptionCustomizationItemDTOExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationItemDTOExteriorColorToJSON(value?: OptionCustomizationItemDTOExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionCustomizationItemDTOExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationItemDTOExteriorColor exists with 'type=${value['type']}'`);
    }
}

