/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    OptionalSelectDTOExteriorColorType,
    OptionalSelectDTOExteriorColorTypeFromJSON,
    OptionalSelectDTOExteriorColorTypeFromJSONTyped,
    OptionalSelectDTOExteriorColorTypeToJSON,
    OptionalSelectDTOString,
    OptionalSelectDTOStringFromJSON,
    OptionalSelectDTOStringFromJSONTyped,
    OptionalSelectDTOStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOExteriorColorValue
 */
export interface OptionCustomizationAttributesDTOExteriorColorValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    hexCode: string;
    /**
     * 
     * @type {OptionalSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    tag: OptionalSelectDTOString;
    /**
     * 
     * @type {OptionalSelectDTOExteriorColorType}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    exteriorColorType: OptionalSelectDTOExteriorColorType;
}

export function OptionCustomizationAttributesDTOExteriorColorValueFromJSON(json: any): OptionCustomizationAttributesDTOExteriorColorValue {
    return OptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOExteriorColorValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'hexCode': json['hexCode'],
        'tag': OptionalSelectDTOStringFromJSON(json['tag']),
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeFromJSON(json['exteriorColorType']),
    };
}

export function OptionCustomizationAttributesDTOExteriorColorValueToJSON(value?: OptionCustomizationAttributesDTOExteriorColorValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
        'hexCode': value.hexCode,
        'tag': OptionalSelectDTOStringToJSON(value.tag),
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeToJSON(value.exteriorColorType),
    };
}


