import { roofColorRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, numberConverterGroup, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { RoofColorFilterOptionsDTO, RoofColorItemDTO, RoofColorSortField, SortDirection } from "../../../../generated/pdsapi"
import { roofColorApi, RoofColorFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { RoofColorCatalogueColumnsConfig } from "./columns/RoofColorCatalogueColumns"
import { RoofColorCatalogueFilter } from "./filter/RoofColorCatalogueFilter"
import { RoofColorCatalogueFilterTagsConfig } from "./filter/RoofColorCatalogueFilterTagsConfig"

export const RoofColorCatalogueContainerConfig: ProductListContainerConfig<RoofColorItemDTO, RoofColorFilter, RoofColorFilterOptionsDTO, RoofColorSortField> = {
    listName: "Roof Color Catalogue",
    itemsName: "Roof Colors",

    buildDetailsPath: (item) => roofColorRoutes.details(item.orderTypeKey, item.optionCode),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<RoofColorFilter>({
        modelGenerationKeys: stringConverterGroup.optionalArray,
        modelYears: numberConverterGroup.optionalArray,
        orderTypeKeys: stringConverterGroup.optionalArray,
        colorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: RoofColorSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(RoofColorSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: roofColorApi.catalogue.getPage,
    fetchFilterOptions: roofColorApi.catalogue.getFilterOptions,

    defaultShowsFilter: true,
    Filter: RoofColorCatalogueFilter,
    filterTagsConfig: RoofColorCatalogueFilterTagsConfig,

    columnsConfig: RoofColorCatalogueColumnsConfig,
}

export const RoofColorCatalogueContainer = getProductListContainer(RoofColorCatalogueContainerConfig)
