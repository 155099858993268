/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageDTOTechnicalDataItemDTO,
    PageDTOTechnicalDataItemDTOFromJSON,
    PageDTOTechnicalDataItemDTOToJSON,
    PdsMarketplace,
    PdsMarketplaceFromJSON,
    PdsMarketplaceToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    TechnicalDataAttribute,
    TechnicalDataAttributeFromJSON,
    TechnicalDataAttributeToJSON,
    TechnicalDataAttributeDataDTO,
    TechnicalDataAttributeDataDTOFromJSON,
    TechnicalDataAttributeDataDTOToJSON,
    TechnicalDataFilterOptionsDTO,
    TechnicalDataFilterOptionsDTOFromJSON,
    TechnicalDataFilterOptionsDTOToJSON,
    TechnicalDataSortField,
    TechnicalDataSortFieldFromJSON,
    TechnicalDataSortFieldToJSON,
} from '../models';

export interface GetTechnicalDataFilterOptionsRequest {
    languageTag: string;
}

export interface GetTechnicalDataPageRequest {
    technicalDataAttribute: TechnicalDataAttribute;
    languageTag: string;
    offset: number;
    size: number;
    sortField: TechnicalDataSortField;
    sortDirection: SortDirection;
    marketplace?: PdsMarketplace;
    search?: string;
    modelSeriesKeys?: Array<string>;
    modelGenerationKeys?: Array<string>;
    modelYears?: Array<number>;
    orderTypeKeys?: Array<string>;
}

/**
 * 
 */
export class TechnicalDataListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get column definitions for given technical data attribute
     */
    async getTechnicalDataAttributeOptionsRaw(): Promise<runtime.ApiResponse<{ [key: string]: TechnicalDataAttributeDataDTO; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/technical-data/list/technical-data-attribute-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, TechnicalDataAttributeDataDTOFromJSON));
    }

    /**
     * Get column definitions for given technical data attribute
     */
    async getTechnicalDataAttributeOptions(): Promise<{ [key: string]: TechnicalDataAttributeDataDTO; }> {
        const response = await this.getTechnicalDataAttributeOptionsRaw();
        return await response.value();
    }

    /**
     * Get options to filter TechnicalData
     */
    async getTechnicalDataFilterOptionsRaw(requestParameters: GetTechnicalDataFilterOptionsRequest): Promise<runtime.ApiResponse<TechnicalDataFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getTechnicalDataFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/technical-data/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicalDataFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter TechnicalData
     */
    async getTechnicalDataFilterOptions(requestParameters: GetTechnicalDataFilterOptionsRequest): Promise<TechnicalDataFilterOptionsDTO> {
        const response = await this.getTechnicalDataFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of TechnicalData
     */
    async getTechnicalDataPageRaw(requestParameters: GetTechnicalDataPageRequest): Promise<runtime.ApiResponse<PageDTOTechnicalDataItemDTO>> {
        if (requestParameters.technicalDataAttribute === null || requestParameters.technicalDataAttribute === undefined) {
            throw new runtime.RequiredError('technicalDataAttribute','Required parameter requestParameters.technicalDataAttribute was null or undefined when calling getTechnicalDataPage.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getTechnicalDataPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getTechnicalDataPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getTechnicalDataPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getTechnicalDataPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getTechnicalDataPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.technicalDataAttribute !== undefined) {
            queryParameters['technicalDataAttribute'] = requestParameters.technicalDataAttribute;
        }

        if (requestParameters.marketplace !== undefined) {
            queryParameters['marketplace'] = requestParameters.marketplace;
        }

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelSeriesKeys) {
            queryParameters['modelSeriesKeys'] = requestParameters.modelSeriesKeys;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.modelYears) {
            queryParameters['modelYears'] = requestParameters.modelYears;
        }

        if (requestParameters.orderTypeKeys) {
            queryParameters['orderTypeKeys'] = requestParameters.orderTypeKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/technical-data/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOTechnicalDataItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of TechnicalData
     */
    async getTechnicalDataPage(requestParameters: GetTechnicalDataPageRequest): Promise<PageDTOTechnicalDataItemDTO> {
        const response = await this.getTechnicalDataPageRaw(requestParameters);
        return await response.value();
    }

}
