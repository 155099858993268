import { TableFilterTagsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../../../components/tag/Tag"
import { OrderTypeEquipmentFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeEquipmentFilter } from "../../../../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const OrderTypeEquipmentCardFilterTagsConfig: TableFilterTagsConfig<OrderTypeEquipmentFilter, OrderTypeEquipmentFilterOptionsDTO> = ({
    filterOptions,
    filter,
    setFilter,
}) => {
    const filterTags: JSX.Element[] = []

    filter.categories?.forEach((category) => {
        filterTags.push(
            <Tag
                key={`filterTag_category_${category}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newEquipmentCategoryKeys = filter.categories?.filter((key) => category !== key)
                        return {
                            ...filter,
                            categories: newEquipmentCategoryKeys?.length !== 0 ? newEquipmentCategoryKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.categories, category)?.label ?? category}
            </Tag>
        )
    })

    filter.tagKeys?.forEach((equipmentTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_equipmentTagKey_${equipmentTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newEquipmentTagKeys = filter.tagKeys?.filter((key) => equipmentTagKey !== key)
                        return {
                            ...filter,
                            tagKeys: newEquipmentTagKeys?.length !== 0 ? newEquipmentTagKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.tags, equipmentTagKey)?.label ?? equipmentTagKey}
            </Tag>
        )
    })

    filter.families?.forEach((family) => {
        filterTags.push(
            <Tag
                key={`filterTag_category_${family}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newEquipmentFamilies = filter.families?.filter((key) => family !== key)
                        return {
                            ...filter,
                            families: newEquipmentFamilies?.length !== 0 ? newEquipmentFamilies : undefined,
                        }
                    })
                }
            >
                {family}
            </Tag>
        )
    })

    return filterTags
}
