import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { TechnicalDataFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { TechnicalDataFilter } from "../../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const TechnicalDataListFilterTagsConfig: TableFilterTagsConfig<TechnicalDataFilter, TechnicalDataFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.orderTypeKeys?.forEach((orderTypeKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_orderTypeKey_${orderTypeKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newOrderTypeKeys = filter.orderTypeKeys?.filter((key) => orderTypeKey !== key)
                        return {
                            ...filter,
                            orderTypeKeys: newOrderTypeKeys?.length !== 0 ? newOrderTypeKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.modelYearAndGenerationRelatedOrderTypes, orderTypeKey)?.label ?? orderTypeKey}
            </Tag>
        )
    })

    filter.modelYears?.forEach((modelYear) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelYear_${modelYear}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelYears = filter.modelYears?.filter((my) => modelYear !== my)
                        return {
                            ...filter,
                            modelYears: newModelYears?.length !== 0 ? newModelYears : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelYears, modelYear)?.label ?? modelYear}
            </Tag>
        )
    })

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.modelSeriesRelatedModelGenerations, modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.modelSeriesKeys?.forEach((modelSeriesKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelSeriesKey_${modelSeriesKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelSeriesKeys = filter.modelSeriesKeys?.filter((key) => modelSeriesKey !== key)
                        return {
                            ...filter,
                            modelSeriesKeys: newModelSeriesKeys?.length !== 0 ? newModelSeriesKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelSeries, modelSeriesKey)?.label ?? modelSeriesKey}
            </Tag>
        )
    })

    return filterTags
}
