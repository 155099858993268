/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationAttributesDTOExteriorColor,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColor,
    OptionCustomizationAttributesDTOExteriorColorFromJSONTyped,
    OptionCustomizationAttributesDTOExteriorColorToJSON,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorToJSON,
} from './';

/**
 * @type OptionCustomizationAttributesDTOObject
 * 
 * @export
 */
export type OptionCustomizationAttributesDTOObject = { type: 'ExteriorColor' } & OptionCustomizationAttributesDTOExteriorColor | { type: 'PaintToSampleExteriorColor' } & OptionCustomizationAttributesDTOPaintToSampleExteriorColor;

export function OptionCustomizationAttributesDTOObjectFromJSON(json: any): OptionCustomizationAttributesDTOObject {
    return OptionCustomizationAttributesDTOObjectFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionCustomizationAttributesDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'PaintToSampleExteriorColor':
            return {...OptionCustomizationAttributesDTOPaintToSampleExteriorColorFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOObject exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationAttributesDTOObjectToJSON(value?: OptionCustomizationAttributesDTOObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionCustomizationAttributesDTOExteriorColorToJSON(value);
        case 'PaintToSampleExteriorColor':
            return OptionCustomizationAttributesDTOPaintToSampleExteriorColorToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOObject exists with 'type=${value['type']}'`);
    }
}

