import { SortableListItem } from "./SortableList"
import { PIcon, PText } from "@porsche-design-system/components-react"
import { color, spacing } from "@porsche-design-system/utilities"
import styled from "styled-components"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

export interface SortableListCellProps {
    item: SortableListItem
}

export const SortableListCell = (props: SortableListCellProps) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.item.key })

    return (
        <SortableListElementWrapper
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            {...attributes}
            {...listeners}
        >
            <SortableListIconElementSpacing>
                <PIcon name={"menu-lines"} />
            </SortableListIconElementSpacing>
            <PText>{props.item.label}</PText>
        </SortableListElementWrapper>
    )
}

const SortableListElementWrapper = styled.li`
    display: flex;
    align-items: center;
    background: ${color.background.default};
    padding-top: ${spacing[16]};
    padding-bottom: ${spacing[16]};
    cursor: move;

    border-bottom: 1px solid ${color.neutralContrast.low};

    &:last-child {
        border-bottom: none;
    }
`

const SortableListIconElementSpacing = styled.div`
    margin-left: ${spacing[16]};
    margin-right: ${spacing[16]};
`
