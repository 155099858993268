import { optionCustomizationRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import {
    OptionCustomizationFilterOptionsDTO,
    OptionCustomizationItemDTO,
    OptionCustomizationSortField,
    OptionCustomizationTypeDTO,
    SortDirection,
} from "../../../../generated/pdsapi"
import { optionCustomizationApi, OptionCustomizationFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { OptionCustomizationListColumnsConfig } from "./columns/OptionCustomizationListColumns"
import { OptionCustomizationListFilter } from "./filter/OptionCustomizationListFilter"
import { OptionCustomizationListFilterTagsConfig } from "./filter/OptionCustomizationListFilterTagsConfig"
import { optionCustomizationTypeToEnum } from "../optionCustomizationTypes"

export const OptionCustomizationListContainerConfig: ProductListContainerConfig<
    OptionCustomizationItemDTO,
    OptionCustomizationFilter,
    OptionCustomizationFilterOptionsDTO,
    OptionCustomizationSortField
> = {
    listName: "Option Customizations",
    createItemLabel: "Add new Option Customization",

    buildDetailsPath: (optionCustomization) =>
        optionCustomizationRoutes.details(optionCustomizationTypeToEnum(optionCustomization.type), optionCustomization.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OptionCustomizationFilter>({
        types: getEnumConverterGroup(OptionCustomizationTypeDTO).optionalArray,
        modelGenerationKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: OptionCustomizationSortField.OPTION_CODE,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(OptionCustomizationSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: optionCustomizationApi.list.getPage,
    fetchFilterOptions: optionCustomizationApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: OptionCustomizationListFilter,
    filterTagsConfig: OptionCustomizationListFilterTagsConfig,

    columnsConfig: OptionCustomizationListColumnsConfig,
}

export const OptionCustomizationListContainer = getProductListContainer(OptionCustomizationListContainerConfig)
