import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { OptionCustomizationKey } from "../OptionCustomizationKey"
import { CustomizationVideoUpdateDTO, OptionCustomizationVideoLocalizationsDTO } from "../../../../../generated/pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsTab } from "../../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { WidgetWrapper } from "../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { TextSelector } from "../../../../../components/selectors/textselector/TextSelector"
import { conditionalValueLabelOf } from "../../../../../common/conditionalValueLabel"
import { OptionVideosCard } from "../../../../../viewtemplates/details/cards/optionvideoscard/OptionVideosCard"
import { useToast } from "@finder/ui-kit"
import { stringConverterGroup, useURLSearchParamState } from "../../../../../common/useURLSearchParamState"
import { showErrorToast } from "../../../../../common/errorToastHelper"
import { optionCustomizationApi } from "../../../../../pdsapi"

export interface VideoTabProps {
    getVideo: typeof optionCustomizationApi.details.getVideos
    updateDefaultValue: typeof optionCustomizationApi.details.updateDefaultVideo
    updateConditionalValue: typeof optionCustomizationApi.details.updateConditionalValueVideo
}

export const OptionCustomizationVideosTab: FC<ProductDetailsTabConfigContentProps<OptionCustomizationKey> & VideoTabProps> = (props) => {
    const { itemKey, getVideo, updateDefaultValue, updateConditionalValue, urlPathParamsPrefix, reloadDataIndicator } = props

    const toastRef = useRef(useToast())
    const [conditionalValueKey, setConditionalValueKey] = useURLSearchParamState(
        `${urlPathParamsPrefix}.conditionalValueKey`,
        undefined,
        stringConverterGroup.optional
    )

    const [customizationVideoLocalizations, setCustomizationVideoLocalizations] = useState<OptionCustomizationVideoLocalizationsDTO | undefined>()

    const videoLocalizationsPromise = useMemo(() => getVideo(itemKey), [getVideo, itemKey, reloadDataIndicator])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setCustomizationVideoLocalizations(await videoLocalizationsPromise)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchData()
    }, [videoLocalizationsPromise])

    const getContentData = useCallback(
        () =>
            videoLocalizationsPromise.then((videoLocalizations) =>
                conditionalValueKey === undefined
                    ? videoLocalizations.defaultValue
                    : videoLocalizations.conditionalValues.find((conditionalValue) => conditionalValue.key === conditionalValueKey)!.value
            ),
        [videoLocalizationsPromise, conditionalValueKey]
    )

    const updateData = useCallback(
        ({ key, type, update }: OptionCustomizationKey & { update: CustomizationVideoUpdateDTO }) =>
            conditionalValueKey === undefined
                ? updateDefaultValue({
                      key,
                      type,
                      customizationVideoUpdateDTO: update,
                  })
                : updateConditionalValue({
                      key,
                      type,
                      conditionalValueKey,
                      customizationVideoUpdateDTO: update,
                  }),
        [conditionalValueKey, updateDefaultValue, updateConditionalValue]
    )

    return (
        <ProductDetailsTab>
            <WidgetWrapper label={"Condition"}>
                <TextSelector
                    label={undefined}
                    options={[
                        {
                            value: "",
                            label: "Default Value",
                        },
                        ...(customizationVideoLocalizations?.conditionalValues.map((conditionalValue) => ({
                            value: conditionalValue.key,
                            label: conditionalValueLabelOf(conditionalValue),
                        })) ?? []),
                    ]}
                    value={conditionalValueKey ?? ""}
                    onChange={(value) => setConditionalValueKey(value ? value : undefined)}
                />
            </WidgetWrapper>
            <OptionVideosCard {...props} label="Video" getData={getContentData} updateData={updateData} />
        </ProductDetailsTab>
    )
}
