import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ActiveTag } from "../../../../../components/filteredpagedtable/filtertags/activetag/ActiveTag"
import { ExteriorColorTagFilter } from "../../../../../pdsapi"
import { JSX } from "react"

export const ExteriorColorTagListFilterTagsConfig: TableFilterTagsConfig<ExteriorColorTagFilter, unknown> = ({ filter, setFilter }) => {
    const filterTags: JSX.Element[] = []

    if (filter.active !== undefined) {
        filterTags.push(
            <ActiveTag
                key={"active_tag"}
                active={filter.active}
                removeActiveFlag={() =>
                    setFilter((findParams) => ({
                        ...findParams,
                        active: undefined,
                    }))
                }
            />
        )
    }

    return filterTags
}
