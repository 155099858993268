import { showErrorToast } from "../../../../../common/errorToastHelper"
import { browserLanguage } from "../../../../../common/global"
import { getEnumConverterGroup, getObjectURLParamConverter, numberConverterGroup, useURLSearchParamState } from "../../../../../common/useURLSearchParamState"
import { StyledCard } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ViewContentWrapper } from "../../../../../components/layouts/viewcontentwrapper/ViewContentWrapper"
import { MarketSelector } from "../../../../../components/selectors/marketselector/MarketSelector"
import { TechnicalDataMenu, TechnicalDataMenuItemIndex } from "../../../../../components/technicaldatamenu/TechnicalDataMenu"
import { technicalDataMenuItemGroups } from "../../../../../components/technicaldatamenu/technicalDataMenuItemGroups"
import {
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO,
    OrderTypeTechnicalDataAttributeUpdateDTO,
    OrderTypeTechnicalDataRelatedDataDTO,
    PdsMarketplace,
} from "../../../../../generated/pdsapi"
import { orderTypeApi } from "../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { useOrderTypeDetailsContext } from "../OrderTypeDetailsContext"
import { OrderTypeTechnicalDataTabContext } from "./OrderTypeTechnicalDataTabContext"
import { OrderTypeTechnicalDataAttributeCardContent } from "./attribute/OrderTypeTechnicalDataAttributeCardContent"
import { useToast } from "@finder/ui-kit"
import { PFlex, PFlexItem, PHeadline, PText } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"

export const OrderTypeTechnicalDataTab: FC<ProductDetailsTabConfigContentProps> = (props) => {
    const toastRef = useRef(useToast())
    const { isTechnicalDataEditable } = useOrderTypeDetailsContext()

    const [selectedTechnicalDataMenuItem, setSelectedTechnicalDataMenuItem] = useURLSearchParamState<TechnicalDataMenuItemIndex>(
        `${props.urlPathParamsPrefix}.selectedTechnicalDataMenuItem`,
        { groupIndex: 0, itemIndex: 0 },
        getObjectURLParamConverter({
            groupIndex: numberConverterGroup.required,
            itemIndex: numberConverterGroup.required,
        })
    )
    const [selectedMarketplace, setSelectedMarketplace] = useURLSearchParamState<PdsMarketplace | undefined>(
        `${props.urlPathParamsPrefix}.selectedMarketplace`,
        undefined,
        getEnumConverterGroup(PdsMarketplace).optional
    )
    const [relatedData, setRelatedData] = useState<OrderTypeTechnicalDataRelatedDataDTO>({
        fuelTypes: [],
        transmissions: [],
        engineLayouts: [],
        engineDesigns: [],
        possibleOptionCodes: [],
    })

    useEffect(() => {
        const fetchRelatedData = async () => {
            try {
                const relatedData = await orderTypeApi.details.technicalData.getRelatedData({
                    ...props.itemKey,
                    languageTag: browserLanguage,
                })
                setRelatedData(relatedData)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        fetchRelatedData()
    }, [props.itemKey])

    const selectedItem = technicalDataMenuItemGroups[selectedTechnicalDataMenuItem.groupIndex].items[selectedTechnicalDataMenuItem.itemIndex]
    const attributes = useMemo(
        () =>
            selectedItem.relatedAttributes.map((technicalDataAttribute) => ({
                key: technicalDataAttribute,
                get:
                    selectedMarketplace !== undefined
                        ? ({ key }: { key: string }) =>
                              orderTypeApi.details.technicalData.getMarketplaceSpecificAttribute({
                                  technicalDataAttribute,
                                  marketplace: selectedMarketplace,
                                  key,
                              })
                        : ({ key }: { key: string }) =>
                              orderTypeApi.details.technicalData
                                  .getGlobalAttribute({
                                      technicalDataAttribute,
                                      key,
                                  })
                                  .then<MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO>((globalAttribute) => ({
                                      attributeName: globalAttribute.attributeName,
                                      columnDefinitions: globalAttribute.columnDefinitions,
                                      globalEquipmentRelations: [],
                                      specificEquipmentRelations: globalAttribute.equipmentRelations.map((er) => ({
                                          equipmentCodes: er.equipmentCodes,
                                          values: Object.fromEntries(Object.entries(er.values).map(([field, value]) => [field, { specificValue: value }])),
                                      })),
                                      disclaimer: globalAttribute.disclaimer,
                                  })),
                update:
                    selectedMarketplace !== undefined
                        ? ({ key, update }: { key: string; update: OrderTypeTechnicalDataAttributeUpdateDTO }) =>
                              orderTypeApi.details.technicalData.updateMarketplaceSpecificAttribute({
                                  key,
                                  technicalDataAttribute,
                                  marketplace: selectedMarketplace,
                                  orderTypeTechnicalDataAttributeUpdateDTO: update,
                              })
                        : ({ key, update }: { key: string; update: OrderTypeTechnicalDataAttributeUpdateDTO }) =>
                              orderTypeApi.details.technicalData.updateGlobalAttribute({
                                  key,
                                  technicalDataAttribute,
                                  orderTypeTechnicalDataAttributeUpdateDTO: update,
                              }),
            })),
        [selectedItem.relatedAttributes, selectedMarketplace]
    )
    return (
        <ViewContentWrapper>
            <OrderTypeTechnicalDataTabContext.Provider value={relatedData}>
                <PFlex>
                    <PFlexItem width={"one-quarter"}>
                        <ContentArea>
                            <StyledCard>
                                <TechnicalDataMenu
                                    selectedIndex={selectedTechnicalDataMenuItem}
                                    groups={technicalDataMenuItemGroups}
                                    onItemSelected={setSelectedTechnicalDataMenuItem}
                                    urlParamsPrefix={`${props.urlPathParamsPrefix}.menu`}
                                />
                            </StyledCard>
                        </ContentArea>
                    </PFlexItem>
                    <PFlexItem width={"three-quarters"}>
                        <PFlex direction={"column"}>
                            <ContentArea>
                                <StyledCard>
                                    <PFlex justifyContent={"space-between"}>
                                        <PFlex direction={"column"}>
                                            <PText>Selected datatype</PText>
                                            <PHeadline variant={"headline-3"}>{selectedItem.label}</PHeadline>
                                        </PFlex>

                                        <PFlexItem width={"one-quarter"}>
                                            <MarketSelector marketplace={selectedMarketplace} onChange={setSelectedMarketplace} includeGlobal={true} />
                                        </PFlexItem>
                                    </PFlex>
                                </StyledCard>
                            </ContentArea>
                            {attributes.map((attribute) => (
                                <ContentArea key={attribute.key}>
                                    <StyledCard>
                                        <ProductDetailsCard
                                            {...props}
                                            key={attribute.key}
                                            label={undefined}
                                            getData={attribute.get}
                                            updateData={attribute.update}
                                            isDataEditable={props.isDataEditable && isTechnicalDataEditable}
                                            Content={OrderTypeTechnicalDataAttributeCardContent}
                                        />
                                    </StyledCard>
                                </ContentArea>
                            ))}
                        </PFlex>
                    </PFlexItem>
                </PFlex>
            </OrderTypeTechnicalDataTabContext.Provider>
        </ViewContentWrapper>
    )
}

const ContentArea = styled.div`
    padding-right: ${spacing[16]};
    padding-bottom: ${spacing[16]};
`
