import { usePdsBootContext } from "../../common/PdsBootContext"
import { Sorting } from "../../common/Sorting"
import { URLParamConverter } from "../../common/useURLSearchParamState"
import { FilteredPagedTable, TableColumnsConfig, TableFilterProps, TableFilterTagsConfig } from "../../components/filteredpagedtable/FilteredPagedTable"
import { FilterFindParams } from "../../components/filteredpagedtable/filterwrapper/FilterWrapper"
import { ViewHeadline } from "../../components/headlines/viewheadline/ViewHeadline"
import { DefaultCardViewContentWrapper } from "../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { Page, PagingFindParams } from "../../components/pagedtable/PagedTable"
import { UserAuthority } from "../../generated/pdsapi"
import { PButton } from "@porsche-design-system/components-react"
import { FC, FunctionComponent, JSX, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

export type ProductListFindParamsWithoutFilter<SORTING_TYPE> = FilterFindParams & PagingFindParams & Sorting<SORTING_TYPE>

type ProductListFindParams<FILTER, SORTING_TYPE> = FILTER & ProductListFindParamsWithoutFilter<SORTING_TYPE>

export interface ProductListContainerConfig<ITEM, FILTER, FILTER_OPTIONS, SORTING_TYPE> {
    listName: string
    itemsName?: string
    createItemLabel?: string

    buildDetailsPath: (item: ITEM) => string

    defaultFilter: FILTER
    filterUrlParamsConverter: URLParamConverter<FILTER>
    defaultSorting: Sorting<SORTING_TYPE>
    sortingUrlParamsConverter: URLParamConverter<Sorting<SORTING_TYPE>>
    fetchPage: (findParams: ProductListFindParams<FILTER, SORTING_TYPE>) => Promise<Page<ITEM>>
    fetchFilterOptions: (params: { languageTag: string }) => Promise<FILTER_OPTIONS>

    defaultShowsFilter: boolean
    Filter: FunctionComponent<TableFilterProps<FILTER, FILTER_OPTIONS>>
    filterTagsConfig: TableFilterTagsConfig<FILTER, FILTER_OPTIONS>

    columnsConfig: TableColumnsConfig<ITEM, SORTING_TYPE>
}

export interface ProductListContainerProps {
    createPath?: string
    sortPath?: string
}

export const getProductListContainer = <ITEM, FILTER, FILTER_OPTIONS, SORTING_TYPE>(
    config: ProductListContainerConfig<ITEM, FILTER, FILTER_OPTIONS, SORTING_TYPE>
) => {
    const ProductListContainer: FC<ProductListContainerProps> = (props) => {
        const { sortPath, createPath } = props
        const navigate = useNavigate()
        const { hasAuthority } = usePdsBootContext()

        const headlineActions = useMemo(() => {
            const headlineActions: JSX.Element[] = []
            if (hasAuthority(UserAuthority.MAINTAIN_DATA)) {
                if (sortPath !== undefined) {
                    headlineActions.push(
                        <NoWrapPButton key={"changeSortingButton"} icon={"sort"} onClick={() => navigate(sortPath)} variant={"tertiary"}>
                            Change Sorting
                        </NoWrapPButton>
                    )
                }
                if (createPath !== undefined) {
                    headlineActions.push(
                        <NoWrapPButton key={"createButton"} icon={"plus"} onClick={() => navigate(createPath)}>
                            {config.createItemLabel ?? ""}
                        </NoWrapPButton>
                    )
                }
            }
            return headlineActions
        }, [hasAuthority, sortPath, createPath, navigate])

        return (
            <>
                <ViewHeadline headline={config.listName} actions={headlineActions} />

                <DefaultCardViewContentWrapper>
                    <FilteredPagedTable
                        itemsName={config.itemsName ?? config.listName}
                        urlParamsPrefix={"list"}
                        columnsConfig={config.columnsConfig}
                        buildDetailsPath={config.buildDetailsPath}
                        fetchPage={config.fetchPage}
                        fetchFilterOptions={config.fetchFilterOptions}
                        defaultFilter={config.defaultFilter}
                        filterUrlParamsConverter={config.filterUrlParamsConverter}
                        defaultSorting={config.defaultSorting}
                        sortingUrlParamsConverter={config.sortingUrlParamsConverter}
                        defaultShowsFilter={config.defaultShowsFilter}
                        FilterComponent={config.Filter}
                        filterTagsConfig={config.filterTagsConfig}
                    />
                </DefaultCardViewContentWrapper>
            </>
        )
    }

    return ProductListContainer
}

const NoWrapPButton = styled(PButton)`
    white-space: nowrap;
`
