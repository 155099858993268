/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EquipmentCustomizationFilterOptionsDTO,
    EquipmentCustomizationFilterOptionsDTOFromJSON,
    EquipmentCustomizationFilterOptionsDTOToJSON,
    EquipmentCustomizationSortField,
    EquipmentCustomizationSortFieldFromJSON,
    EquipmentCustomizationSortFieldToJSON,
    PageDTOEquipmentCustomizationItemDTO,
    PageDTOEquipmentCustomizationItemDTOFromJSON,
    PageDTOEquipmentCustomizationItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetEquipmentCustomizationFilterOptionsRequest {
    languageTag: string;
}

export interface GetEquipmentCustomizationsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: EquipmentCustomizationSortField;
    sortDirection: SortDirection;
    search?: string;
    modelGenerationKeys?: Array<string>;
    optionCodes?: Array<string>;
    equipmentTagKeys?: Array<string>;
}

/**
 * 
 */
export class EquipmentCustomizationListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter EquipmentCustomizations
     */
    async getEquipmentCustomizationFilterOptionsRaw(requestParameters: GetEquipmentCustomizationFilterOptionsRequest): Promise<runtime.ApiResponse<EquipmentCustomizationFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentCustomizationFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentCustomizationFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter EquipmentCustomizations
     */
    async getEquipmentCustomizationFilterOptions(requestParameters: GetEquipmentCustomizationFilterOptionsRequest): Promise<EquipmentCustomizationFilterOptionsDTO> {
        const response = await this.getEquipmentCustomizationFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of EquipmentCustomizations
     */
    async getEquipmentCustomizationsPageRaw(requestParameters: GetEquipmentCustomizationsPageRequest): Promise<runtime.ApiResponse<PageDTOEquipmentCustomizationItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getEquipmentCustomizationsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getEquipmentCustomizationsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getEquipmentCustomizationsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getEquipmentCustomizationsPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getEquipmentCustomizationsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.optionCodes) {
            queryParameters['optionCodes'] = requestParameters.optionCodes;
        }

        if (requestParameters.equipmentTagKeys) {
            queryParameters['equipmentTagKeys'] = requestParameters.equipmentTagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/equipment-customizations/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOEquipmentCustomizationItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of EquipmentCustomizations
     */
    async getEquipmentCustomizationsPage(requestParameters: GetEquipmentCustomizationsPageRequest): Promise<PageDTOEquipmentCustomizationItemDTO> {
        const response = await this.getEquipmentCustomizationsPageRaw(requestParameters);
        return await response.value();
    }

}
