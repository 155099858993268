/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoofColorSortField {
    SORT_INDEX = 'SORT_INDEX'
}

export function RoofColorSortFieldFromJSON(json: any): RoofColorSortField {
    return RoofColorSortFieldFromJSONTyped(json, false);
}

export function RoofColorSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoofColorSortField {
    return json as RoofColorSortField;
}

export function RoofColorSortFieldToJSON(value?: RoofColorSortField | null): any {
    return value as any;
}

