/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageDTORoofColorCustomizationItemDTO,
    PageDTORoofColorCustomizationItemDTOFromJSON,
    PageDTORoofColorCustomizationItemDTOToJSON,
    RoofColorCustomizationFilterOptionsDTO,
    RoofColorCustomizationFilterOptionsDTOFromJSON,
    RoofColorCustomizationFilterOptionsDTOToJSON,
    RoofColorCustomizationSortField,
    RoofColorCustomizationSortFieldFromJSON,
    RoofColorCustomizationSortFieldToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetRoofColorCustomizationFilterOptionsRequest {
    languageTag: string;
}

export interface GetRoofColorCustomizationsPageRequest {
    languageTag: string;
    sortField: RoofColorCustomizationSortField;
    offset: number;
    size: number;
    sortDirection?: SortDirection;
    search?: string;
    modelGenerationKeys?: Array<string>;
    optionCodes?: Array<string>;
    roofColorTagKeys?: Array<string>;
}

/**
 * 
 */
export class RoofColorCustomizationListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter RoofColorCustomizations
     */
    async getRoofColorCustomizationFilterOptionsRaw(requestParameters: GetRoofColorCustomizationFilterOptionsRequest): Promise<runtime.ApiResponse<RoofColorCustomizationFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getRoofColorCustomizationFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoofColorCustomizationFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter RoofColorCustomizations
     */
    async getRoofColorCustomizationFilterOptions(requestParameters: GetRoofColorCustomizationFilterOptionsRequest): Promise<RoofColorCustomizationFilterOptionsDTO> {
        const response = await this.getRoofColorCustomizationFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of RoofColorCustomizations
     */
    async getRoofColorCustomizationsPageRaw(requestParameters: GetRoofColorCustomizationsPageRequest): Promise<runtime.ApiResponse<PageDTORoofColorCustomizationItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getRoofColorCustomizationsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getRoofColorCustomizationsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getRoofColorCustomizationsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getRoofColorCustomizationsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.optionCodes) {
            queryParameters['optionCodes'] = requestParameters.optionCodes;
        }

        if (requestParameters.roofColorTagKeys) {
            queryParameters['roofColorTagKeys'] = requestParameters.roofColorTagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/roof-color-customizations/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTORoofColorCustomizationItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of RoofColorCustomizations
     */
    async getRoofColorCustomizationsPage(requestParameters: GetRoofColorCustomizationsPageRequest): Promise<PageDTORoofColorCustomizationItemDTO> {
        const response = await this.getRoofColorCustomizationsPageRaw(requestParameters);
        return await response.value();
    }

}
