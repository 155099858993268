import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { InteriorCustomizationItemDTO, InteriorCustomizationSortField } from "../../../../../generated/pdsapi"

type InteriorCustomizationColumnBuilder = ColumnBuilder<InteriorCustomizationItemDTO, InteriorCustomizationSortField>

export const InteriorCustomizationListColumnsConfig: TableColumnsConfig<InteriorCustomizationItemDTO, InteriorCustomizationSortField> = ({
    sorting,
    setSorting,
}) => {
    const optionCodeColumn: InteriorCustomizationColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Code",
            sorting: {
                field: InteriorCustomizationSortField.OPTION_CODE,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (interiorCustomization) => interiorCustomization.optionCode,
    }
    const modelGenerationColumn: InteriorCustomizationColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Model Generations",
        },
        buildCellContent: (interiorCustomization) => interiorCustomization.modelGenerationNames.join(", "),
    }
    const interiorColorTagColumn: InteriorCustomizationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Interior Color Tag",
        },
        buildCellContent: (interiorCustomization) => {
            const interiorColorTag = interiorCustomization.defaultValue.color.tag

            return interiorColorTag ? <NamedColorTile label={interiorColorTag.name} hexCode={interiorColorTag.hexCode} /> : "-"
        },
    }
    const interiorMaterialColumn: InteriorCustomizationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Interior Material",
        },
        buildCellContent: (interiorCustomization) => interiorCustomization.defaultValue.materialName,
    }

    return [optionCodeColumn, modelGenerationColumn, interiorColorTagColumn, interiorMaterialColumn]
}
