import { BreakpointCustomizable, PGrid, PGridItem, PHeadline } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { Children, FC, JSX, ReactNode } from "react"
import styled from "styled-components"

export const rowGroupsSpacing = spacing[32]

export interface RowGroupWrapperProps {
    label?: string | JSX.Element
    childrenSize?: BreakpointCustomizable<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>
    childrenContainerSize?: BreakpointCustomizable<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>
    parentSize?: BreakpointCustomizable<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>
    children: ReactNode
}

export const RowGroupWrapper: FC<RowGroupWrapperProps> = (props) => (
    <PGrid>
        <PGridItem size={props.parentSize ?? 3}>{props.label && <PHeadline variant={"headline-5"}>{props.label}</PHeadline>}</PGridItem>
        <PGridItem size={props.childrenContainerSize ?? 9}>
            <StyledPGrid>
                {Children.map(props.children, (child) => (
                    <PGridItem size={props.childrenSize ?? 4}>{child}</PGridItem>
                ))}
            </StyledPGrid>
        </PGridItem>
    </PGrid>
)

const StyledPGrid = styled(PGrid)`
    grid-row-gap: ${spacing[16]};
`
