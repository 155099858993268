import { TableColumnsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../../../components/layouts/table/Table"
import { getSortIndexColumnBuilder } from "../../../../../../../components/pagedtable/columns/columnBuilder"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { OrderTypeOptionItemDTOPaintToSampleExteriorColor, PaintToSampleExteriorColorSortField } from "../../../../../../../generated/pdsapi"

type PaintToSampleExteriorColorItemCardColumnBuilder = ColumnBuilder<OrderTypeOptionItemDTOPaintToSampleExteriorColor, PaintToSampleExteriorColorSortField>

export const OrderTypePaintToSampleExteriorColorsCardColumnsConfig: TableColumnsConfig<
    OrderTypeOptionItemDTOPaintToSampleExteriorColor,
    PaintToSampleExteriorColorSortField
> = ({ sorting, setSorting }) => {
    const previewColumn: PaintToSampleExteriorColorItemCardColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (ptsExteriorColor) => <OptionImage imageKey={ptsExteriorColor.imageKey} sizes={"100px"} />,
    }

    const sortIndexColumn: PaintToSampleExteriorColorItemCardColumnBuilder = getSortIndexColumnBuilder((ptsExteriorColor) => ptsExteriorColor.sortIndex, {
        field: PaintToSampleExteriorColorSortField.SORT_INDEX,
        value: sorting,
        setValue: setSorting,
    })

    const optionCodeColumn: PaintToSampleExteriorColorItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (exteriorColor) => exteriorColor.optionCode,
    }

    const nameColumn: PaintToSampleExteriorColorItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (exteriorColor) => exteriorColor.name ?? "-",
    }

    return [previewColumn, sortIndexColumn, optionCodeColumn, nameColumn]
}
