import { ProductCreateContainerProps } from "./create/ProductCreateContainer"
import { ProductDetailsContainerProps } from "./details/ProductDetailsContainer"
import { ProductListContainerProps } from "./list/ProductListContainer"
import { ProductSortContainerProps } from "./sort/ProductSortContainer"
import { FC, JSX, useCallback } from "react"
import { Route, Routes, useNavigate, useResolvedPath } from "react-router-dom"

export interface ProductRoutesProps {
    listName: string
    List: FC<ProductListContainerProps>
    Details?: FC<ProductDetailsContainerProps>
    Create?: FC<ProductCreateContainerProps>
    Sort?: FC<ProductSortContainerProps>
    customRoutes?: JSX.Element[]
}

export const ProductRoutes: FC<ProductRoutesProps> = ({ listName, List, Details, Create, Sort, customRoutes }) => {
    const navigate = useNavigate()
    const { pathname: parentPath } = useResolvedPath("..", { relative: "path" })
    const buildDetailsPath = useCallback(({ key }: { key: string }) => `${parentPath}/${key}`, [parentPath])

    const createPath = Create && "create"
    const handleCancel = useCallback(() => navigate(parentPath), [navigate, parentPath])
    const createRoute = Create && (
        <Route
            key={"create_route"}
            path={createPath}
            element={<Create handleCancel={handleCancel} buildDetailsPath={buildDetailsPath} parentCrumbs={[{ name: listName, path: parentPath }]} />}
        />
    )

    const sortPath = Sort && "sort"
    const sortRoute = Sort && <Route key={"sort_route"} path={sortPath} element={<Sort listRoute={parentPath} />} />

    const listRoute = <Route key={"list_route"} index element={<List createPath={createPath} sortPath={sortPath} />} />

    const detailsRoute = Details && (
        <Route
            key={"details_route"}
            path={":key"}
            element={
                <Details
                    listPath={parentPath}
                    getItemKey={(params) => ({ key: params.key })}
                    parentCrumbs={[
                        {
                            name: listName,
                            path: parentPath,
                        },
                    ]}
                />
            }
        />
    )

    return (
        <Routes>
            {customRoutes}
            {createRoute}
            {sortRoute}
            {detailsRoute}
            {listRoute}
        </Routes>
    )
}
