import { OrderTypeOptionsCardProps } from "../OrderTypeOptionsCard"
import { OptionSortField, OrderTypeOptionFilterOptionsDTO, OrderTypeOptionItemDTO, OrderTypeOptionTypeDTO } from "../../../../../../generated/pdsapi"
import { paintToSampleExteriorColorRoutes } from "../../../../../../common/routes"
import { orderTypeApi, OrderTypeOptionFilter } from "../../../../../../pdsapi"
import { getEnumConverterGroup, getObjectURLParamConverter } from "../../../../../../common/useURLSearchParamState"
import { OrderTypeOptionItemsCardColumnsConfig } from "./columns/OrderTypeOptionItemsCardColumns"
import { OrderTypeOptionItemsCardFilter } from "./filter/OrderTypeOptionItemsCardFilter"
import { OrderTypeOptionItemsCardFilterTagsConfig } from "./filter/OrderTypeOptionItemsCardFilterTagsConfig"

export const orderTypeOptionItemsCardConfig: OrderTypeOptionsCardProps<
    OrderTypeOptionItemDTO,
    OrderTypeOptionFilter,
    OrderTypeOptionFilterOptionsDTO,
    OptionSortField
> = {
    label: "Options",

    getCreateRoute: paintToSampleExteriorColorRoutes.create,
    getDetailsRoute: paintToSampleExteriorColorRoutes.details,
    getOptions: orderTypeApi.details.options.getAll,
    getFilterOptions: orderTypeApi.details.options.getAllFilterOptions,

    defaultSortField: OptionSortField.SORT_INDEX,
    sortFieldEnum: OptionSortField,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeOptionFilter>({
        types: getEnumConverterGroup(OrderTypeOptionTypeDTO).optionalArray,
    }),

    columnsConfig: OrderTypeOptionItemsCardColumnsConfig,
    FilterComponent: OrderTypeOptionItemsCardFilter,
    filterTagsConfig: OrderTypeOptionItemsCardFilterTagsConfig,

    isInitiallyExpanded: true,
}
