/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InteriorCustomizationAttributesValueUpdateDTO,
    InteriorCustomizationAttributesValueUpdateDTOFromJSON,
    InteriorCustomizationAttributesValueUpdateDTOFromJSONTyped,
    InteriorCustomizationAttributesValueUpdateDTOToJSON,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueUpdateDTO,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueUpdateDTOFromJSON,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueUpdateDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueUpdateDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO
 */
export interface OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {InteriorCustomizationAttributesValueUpdateDTO}
     * @memberof OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO
     */
    defaultValue: InteriorCustomizationAttributesValueUpdateDTO;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueUpdateDTO>}
     * @memberof OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueUpdateDTO>;
}

export function OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTOFromJSON(json: any): OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO {
    return OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTOFromJSONTyped(json, false);
}

export function OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': InteriorCustomizationAttributesValueUpdateDTOFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueUpdateDTOFromJSON)),
    };
}

export function OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTOToJSON(value?: OldOptionCustomizationAttributesUpdateDTOInteriorCustomizationAttributesValueUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': InteriorCustomizationAttributesValueUpdateDTOToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTOInteriorCustomizationAttributesValueUpdateDTOToJSON)),
    };
}


