/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketplaceDTOLanguage,
    MarketplaceDTOLanguageFromJSON,
    MarketplaceDTOLanguageFromJSONTyped,
    MarketplaceDTOLanguageToJSON,
    PdsMarketplace,
    PdsMarketplaceFromJSON,
    PdsMarketplaceFromJSONTyped,
    PdsMarketplaceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MarketplaceDTO
 */
export interface MarketplaceDTO {
    /**
     * 
     * @type {PdsMarketplace}
     * @memberof MarketplaceDTO
     */
    marketplace: PdsMarketplace;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceDTO
     */
    label: string;
    /**
     * 
     * @type {Array<MarketplaceDTOLanguage>}
     * @memberof MarketplaceDTO
     */
    languages: Array<MarketplaceDTOLanguage>;
}

export function MarketplaceDTOFromJSON(json: any): MarketplaceDTO {
    return MarketplaceDTOFromJSONTyped(json, false);
}

export function MarketplaceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marketplace': PdsMarketplaceFromJSON(json['marketplace']),
        'label': json['label'],
        'languages': ((json['languages'] as Array<any>).map(MarketplaceDTOLanguageFromJSON)),
    };
}

export function MarketplaceDTOToJSON(value?: MarketplaceDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marketplace': PdsMarketplaceToJSON(value.marketplace),
        'label': value.label,
        'languages': ((value.languages as Array<any>).map(MarketplaceDTOLanguageToJSON)),
    };
}


