import { roofColorTagRoutes } from "../../../../../../common/routes"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import {
    OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO,
    OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO,
} from "../../../../../../generated/pdsapi"
import {
    OptionCustomizationDetailsAttributesCardContent,
    OptionCustomizationValueContent,
} from "../../../../../../components/optioncustomization/OptionCustomizationDetailsAttributesCardContent"

type AttributesDTO = OldOptionCustomizationAttributesDTORoofColorCustomizationAttributesValueDTO
type Value = AttributesDTO["defaultValue"]
type AttributesUpdateDTO = OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO
type ValueUpdate = AttributesUpdateDTO["defaultValue"]

export const RoofColorCustomizationDetailsAttributesCardContent: FC<ProductDetailsCardContentProps<AttributesDTO, AttributesUpdateDTO>> = (props) => (
    <OptionCustomizationDetailsAttributesCardContent
        {...props}
        convertValueToValueUpdate={convertValueToValueUpdate}
        CustomizationValueContent={CustomizationValueContent}
    />
)

const convertValueToValueUpdate = (value: Value): ValueUpdate => ({
    name: value.name,
    description: value.description,
    hexCode: value.hexCode,
    roofColorTagKey: value.tag?.value?.key,
})

const CustomizationValueContent: OptionCustomizationValueContent<Value, ValueUpdate> = ({ getValue, property, formDisplayData }) => {
    const attributes = formDisplayData.data
    const value = attributes && getValue(attributes)
    const [hexCode, setHexCode] = useSyncState(value?.hexCode)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={property.name}
                getViewProps={(data) => ({
                    value: getValue(data).name,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).name,
                    optional: true,
                })}
            />
            <DetailsDefaultLocalizationsFormRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={property.description}
                getViewProps={(data) => ({
                    value: getValue(data).description,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).description,
                    optional: true,
                })}
            />
            <RowGroupWrapper label={"Color"}>
                <FormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.hexCode}
                    FormElement={getColorPickerFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={() => ({
                        hexValue: hexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={() => ({
                        hexValue: hexCode,
                        disableGlossy: true,
                        onChange: setHexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Roof Color Tag"}
                    formDisplayData={formDisplayData}
                    field={property.roofColorTagKey}
                    FormElement={getTagFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => {
                        const tagValue = getValue(data).tag?.value
                        return {
                            tag: tagValue && toTagOption(tagValue, roofColorTagRoutes.details),
                        }
                    }}
                    getEditProps={(data) => {
                        const tag = getValue(data).tag
                        const options = getValue(data).tag?.options ?? []
                        return {
                            value: tag?.value?.key ?? "",
                            options: [
                                {
                                    value: "",
                                    label: "-",
                                },
                                ...options.map((tag) => toOption(tag)),
                            ],
                            optional: true,
                        }
                    }}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
