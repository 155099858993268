import { exteriorColorRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, numberConverterGroup, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { ExteriorColorFilterOptionsDTO, ExteriorColorItemDTO, ExteriorColorSortField, ExteriorColorType, SortDirection } from "../../../../generated/pdsapi"
import { exteriorColorApi, ExteriorColorFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { ExteriorColorCatalogueColumnsConfig } from "./columns/ExteriorColorCatalogueColumns"
import { ExteriorColorCatalogueFilter } from "./filter/ExteriorColorCatalogueFilter"
import { ExteriorColorCatalogueFilterTagsConfig } from "./filter/ExteriorColorCatalogueFilterTagsConfig"

export const ExteriorColorCatalogueContainerConfig: ProductListContainerConfig<
    ExteriorColorItemDTO,
    ExteriorColorFilter,
    ExteriorColorFilterOptionsDTO,
    ExteriorColorSortField
> = {
    listName: "Exterior Color Catalogue",
    itemsName: "Exterior Colors",

    buildDetailsPath: (item) => exteriorColorRoutes.details(item.orderTypeKey, item.optionCode),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<ExteriorColorFilter>({
        modelGenerationKeys: stringConverterGroup.optionalArray,
        modelYears: numberConverterGroup.optionalArray,
        orderTypeKeys: stringConverterGroup.optionalArray,
        exteriorColorTypes: getEnumConverterGroup(ExteriorColorType).optionalArray,
        colorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: ExteriorColorSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(ExteriorColorSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: exteriorColorApi.catalogue.getPage,
    fetchFilterOptions: exteriorColorApi.catalogue.getFilterOptions,

    defaultShowsFilter: true,
    Filter: ExteriorColorCatalogueFilter,
    filterTagsConfig: ExteriorColorCatalogueFilterTagsConfig,

    columnsConfig: ExteriorColorCatalogueColumnsConfig,
}

export const ExteriorColorCatalogueContainer = getProductListContainer(ExteriorColorCatalogueContainerConfig)
