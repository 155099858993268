import { ProductRoutes } from "../../../viewtemplates/ProductRoutes"
import { FC } from "react"
import { OptionCustomizationListContainer } from "./list/OptionCustomizationListContainer"
import { OptionCustomizationCreateContainer } from "./create/OptionCustomizationCreateContainer"

export const OptionCustomizationRoutes: FC = (props) =>
    ProductRoutes({
        ...props,
        listName: "Option Customizations",
        List: OptionCustomizationListContainer,
        Details: undefined,
        Create: OptionCustomizationCreateContainer,
    })
