import { roofColorCustomizationRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import {
    RoofColorCustomizationFilterOptionsDTO,
    RoofColorCustomizationItemDTO,
    RoofColorCustomizationSortField,
    SortDirection,
} from "../../../../generated/pdsapi"
import { roofColorCustomizationApi, RoofColorCustomizationFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { RoofColorCustomizationListColumnsConfig } from "./columns/RoofColorCustomizationListColumns"
import { RoofColorCustomizationListFilter } from "./filter/RoofColorCustomizationListFilter"
import { RoofColorCustomizationListFilterTagsConfig } from "./filter/RoofColorCustomizationListFilterTagsConfig"

export const RoofColorCustomizationProductListContainerConfig: ProductListContainerConfig<
    RoofColorCustomizationItemDTO,
    RoofColorCustomizationFilter,
    RoofColorCustomizationFilterOptionsDTO,
    RoofColorCustomizationSortField
> = {
    listName: "Roof Color Customizations",
    createItemLabel: "Add new Roof Color Customization",

    buildDetailsPath: (roofColorCustomization) => roofColorCustomizationRoutes.details(roofColorCustomization.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<RoofColorCustomizationFilter>({
        optionCodes: stringConverterGroup.optionalArray,
        modelGenerationKeys: stringConverterGroup.optionalArray,
        roofColorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: RoofColorCustomizationSortField.OPTION_CODE,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(RoofColorCustomizationSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: roofColorCustomizationApi.list.getPage,
    fetchFilterOptions: roofColorCustomizationApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: RoofColorCustomizationListFilter,
    filterTagsConfig: RoofColorCustomizationListFilterTagsConfig,

    columnsConfig: RoofColorCustomizationListColumnsConfig,
}

export const RoofColorCustomizationListContainer = getProductListContainer(RoofColorCustomizationProductListContainerConfig)
