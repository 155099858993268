import { usePdsBootContext } from "../../../common/PdsBootContext"
import { TextSelector } from "../textselector/TextSelector"
import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { PFlex, PFlexItem } from "@porsche-design-system/components-react"
import { useEffect, useMemo } from "react"
import { PdsMarketplace } from "../../../generated/pdsapi"

interface MarketLanguageSelectorProps {
    marketplace: PdsMarketplace | undefined
    languageTag: string | undefined
    onChange: (languageTag: string | undefined) => void
}

export const MarketLanguageSelector = ({ marketplace, languageTag, onChange }: MarketLanguageSelectorProps) => {
    const { supportedMarketplaces } = usePdsBootContext()
    const marketplaceDTO = supportedMarketplaces.find((marketplaceDTO) => marketplaceDTO.marketplace === marketplace)

    const options: SelectOption[] = useMemo(
        () =>
            marketplaceDTO?.languages?.map((language) => ({
                value: language.languageTag.toString(),
                label: language.label,
            })) ?? [],
        [marketplaceDTO]
    )
    useEffect(() => {
        if (options.length && !options.find((option) => option.value === languageTag)) {
            onChange(options[0].value)
        }
    }, [options])

    return (
        <PFlex alignItems={"center"}>
            <PFlexItem width={"full"}>
                <TextSelector label={"Language"} value={languageTag ?? "-"} onChange={onChange} options={options} />
            </PFlexItem>
        </PFlex>
    )
}
