import { interiorRoutes } from "../../../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../../../common/useURLSearchParamState"
import { InteriorMaterial, InteriorSortField, OrderTypeInteriorFilterOptionsDTO, OrderTypeInteriorItemDTO } from "../../../../../../generated/pdsapi"
import { orderTypeApi, OrderTypeInteriorsFilter } from "../../../../../../pdsapi"
import { OrderTypeOptionsCardProps } from "../OrderTypeOptionsCard"
import { OrderTypeInteriorsCardColumnsConfig } from "./columns/OrderTypeInteriorsCardColumns"
import { OrderTypeInteriorsCardFilter } from "./filter/OrderTypeInteriorsCardFilter"
import { OrderTypeInteriorsCardFilterTagsConfig } from "./filter/OrderTypeInteriorsCardFilterTagsConfig"

export const orderTypeInteriorsCardConfig: OrderTypeOptionsCardProps<
    OrderTypeInteriorItemDTO,
    OrderTypeInteriorsFilter,
    OrderTypeInteriorFilterOptionsDTO,
    InteriorSortField
> = {
    label: "Interiors",

    getCreateRoute: interiorRoutes.create,
    getDetailsRoute: interiorRoutes.details,
    getOptions: orderTypeApi.details.options.getInteriors,
    getFilterOptions: orderTypeApi.details.options.getInteriorFilterOptions,

    defaultSortField: InteriorSortField.SORT_INDEX,
    sortFieldEnum: InteriorSortField,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeInteriorsFilter>({
        colorTagKeys: stringConverterGroup.optionalArray,
        materials: getEnumConverterGroup(InteriorMaterial).optionalArray,
    }),

    columnsConfig: OrderTypeInteriorsCardColumnsConfig,
    FilterComponent: OrderTypeInteriorsCardFilter,
    filterTagsConfig: OrderTypeInteriorsCardFilterTagsConfig,

    isInitiallyExpanded: false,
}
