/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrderTypeStandardEquipmentDTO,
    OrderTypeStandardEquipmentDTOFromJSON,
    OrderTypeStandardEquipmentDTOToJSON,
    PdsMarketplace,
    PdsMarketplaceFromJSON,
    PdsMarketplaceToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
} from '../models';

export interface GetlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRequest {
    key: string;
    marketplace: PdsMarketplace;
    languageTag: string;
}

/**
 * 
 */
export class OrderTypeStandardEquipmentDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get {languageTag}OfStandardEquipmentByMarketplace of OrderType with given key
     */
    async getlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRaw(requestParameters: GetlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRequest): Promise<runtime.ApiResponse<OrderTypeStandardEquipmentDTO>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType.');
        }

        if (requestParameters.marketplace === null || requestParameters.marketplace === undefined) {
            throw new runtime.RequiredError('marketplace','Required parameter requestParameters.marketplace was null or undefined when calling getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/standard-equipments/standard-equipment/{marketplace}/{languageTag}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"marketplace"}}`, encodeURIComponent(String(requestParameters.marketplace))).replace(`{${"languageTag"}}`, encodeURIComponent(String(requestParameters.languageTag))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeStandardEquipmentDTOFromJSON(jsonValue));
    }

    /**
     * Get {languageTag}OfStandardEquipmentByMarketplace of OrderType with given key
     */
    async getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType(requestParameters: GetlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRequest): Promise<OrderTypeStandardEquipmentDTO> {
        const response = await this.getlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRaw(requestParameters);
        return await response.value();
    }

}
