import { TableColumnsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../../../components/layouts/table/Table"
import { getSortIndexColumnBuilder } from "../../../../../../../components/pagedtable/columns/columnBuilder"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { OptionSortField, OrderTypeOptionItemDTO } from "../../../../../../../generated/pdsapi"

type OptionItemCardColumnBuilder = ColumnBuilder<OrderTypeOptionItemDTO, OptionSortField>

export const OrderTypeOptionItemsCardColumnsConfig: TableColumnsConfig<OrderTypeOptionItemDTO, OptionSortField> = ({ sorting, setSorting }) => {
    const previewColumn: OptionItemCardColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (option) => <OptionImage imageKey={option.imageKey} sizes={"100px"} />,
    }

    const typeColumn: OptionItemCardColumnBuilder = {
        width: 30,
        headerCellProps: {
            content: "Type",
        },
        buildCellContent: (option) => {
            switch (option.type) {
                case "ExteriorColor":
                    return "EC"
                case "PaintToSampleExteriorColor":
                    return "PTS"
            }
        },
    }

    const sortIndexColumn: OptionItemCardColumnBuilder = getSortIndexColumnBuilder((option) => option.sortIndex, {
        field: OptionSortField.SORT_INDEX,
        value: sorting,
        setValue: setSorting,
    })

    const optionCodeColumn: OptionItemCardColumnBuilder = {
        width: 100,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (option) => option.optionCode,
    }

    const nameColumn: OptionItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (option) => option.name ?? "-",
    }

    return [previewColumn, typeColumn, sortIndexColumn, optionCodeColumn, nameColumn]
}
