import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { DerivativeCategoryItemDTO, DerivativeCategorySortField } from "../../../../../generated/pdsapi"

type DerivativeCategoryColumnBuilder = ColumnBuilder<DerivativeCategoryItemDTO, DerivativeCategorySortField>

export const DerivativeCategoryListColumnsConfig: TableColumnsConfig<DerivativeCategoryItemDTO, DerivativeCategorySortField> = ({ sorting, setSorting }) => {
    const sortIndexColumn: DerivativeCategoryColumnBuilder = {
        width: 40,
        headerCellProps: {
            content: "#",
            sorting: {
                field: DerivativeCategorySortField.SORT_INDEX,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (derivativeCategory) => derivativeCategory.sortIndex.toString(),
    }
    const nameColumn: DerivativeCategoryColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (derivativeCategory) => derivativeCategory.name,
    }
    const modelSeriesColumn: DerivativeCategoryColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Model Series",
        },
        buildCellContent: (derivativeCategory) => derivativeCategory.modelSeries,
    }

    return [sortIndexColumn, nameColumn, modelSeriesColumn]
}
