import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { DerivativeItemDTO, DerivativeSortField } from "../../../../../generated/pdsapi"

type DerivativeColumnBuilder = ColumnBuilder<DerivativeItemDTO, DerivativeSortField>

export const DerivativeListColumnsConfig: TableColumnsConfig<DerivativeItemDTO, DerivativeSortField> = ({ sorting, setSorting }) => {
    const sortIndexColumn: DerivativeColumnBuilder = {
        width: 40,
        headerCellProps: {
            content: "#",
            sorting: {
                field: DerivativeSortField.SORT_INDEX,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (derivative) => derivative.sortIndex.toString(),
    }
    const nameColumn: DerivativeColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (derivative) => derivative.name,
    }
    const derivativeCategoryColumn: DerivativeColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Derivative Category",
        },
        buildCellContent: (derivative) => derivative.derivativeCategory,
    }
    const modelSeriesColumn: DerivativeColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Model Series",
        },
        buildCellContent: (derivative) => derivative.modelSeries,
    }
    const bodyTypeColumn: DerivativeColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Body Type",
        },
        buildCellContent: (derivative) => derivative.bodyType,
    }

    return [sortIndexColumn, nameColumn, derivativeCategoryColumn, modelSeriesColumn, bodyTypeColumn]
}
