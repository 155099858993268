import { technicalDataMenuItemGroups } from "../components/technicaldatamenu/technicalDataMenuItemGroups"
import _ from "lodash"
import { OptionCustomizationTypeDTO, OrderTypeOptionTypeDTO, PdsMarketplace } from "../generated/pdsapi"

type Group = "models" | "equipment" | "exterior-colors" | "interiors" | "roof-colors" | "paint-to-sample-exterior-colors"

const buildProductRoutes = (group: Group, name: string) => ({
    list: `/${group}/${name}`,
    create: (params?: URLSearchParams) => (params ? `/${group}/${name}/create?${params.toString()}` : `/${group}/${name}/create`),
    details: (key: string, params?: URLSearchParams) => (params ? `/${group}/${name}/${key}?${params.toString()}` : `/${group}/${name}/${key}`),
})

export const modelSeriesRoutes = buildProductRoutes("models", "model-series")
export const modelGenerationRoutes = buildProductRoutes("models", "model-generations")
export const derivativeRoutes = buildProductRoutes("models", "derivatives")
export const derivativeCategoriesRoutes = buildProductRoutes("models", "derivative-categories")
export const orderTypeRoutes = buildProductRoutes("models", "order-types")

export interface OptionRoutes {
    details: (orderTypeKey: string, optionCode: string) => string
    list: (orderTypeKey: string) => string
    create: (orderTypeKey: string, params?: URLSearchParams) => string
}

const buildOrderTypeOptionRoutes = (optionPath: string): OptionRoutes => ({
    details: (orderTypeKey: string, optionCode: string) => `/models/order-types/${orderTypeKey}/${optionPath}/${encodeURIComponent(optionCode)}`,
    list: (orderTypeKey: string) => `/models/order-types/${orderTypeKey}?selectedTab=2&tab2.${_.camelCase(optionPath)}.isExpanded=true`,
    create: (orderTypeKey: string, params?: URLSearchParams) =>
        params ? `/models/order-types/${orderTypeKey}/${optionPath}/create?${params.toString()}` : `/models/order-types/${orderTypeKey}/${optionPath}/create`,
})

export const optionRoutes = {
    details: (orderTypeKey: string, optionType: OrderTypeOptionTypeDTO, optionCode: string) => {
        switch (optionType) {
            case OrderTypeOptionTypeDTO.EXTERIOR_COLOR:
                return exteriorColorRoutes.details(orderTypeKey, optionCode)
            case OrderTypeOptionTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR:
                return `/models/order-types/${orderTypeKey}/options/${optionType}/${encodeURIComponent(optionCode)}`
        }
    },
}
export const equipmentRoutes = buildOrderTypeOptionRoutes("equipment")
export const exteriorColorRoutes = buildOrderTypeOptionRoutes("exterior-colors")
export const paintToSampleExteriorColorRoutes = buildOrderTypeOptionRoutes("paint-to-sample-exterior-colors")
export const interiorRoutes = buildOrderTypeOptionRoutes("interiors")
export const roofColorRoutes = buildOrderTypeOptionRoutes("roof-colors")

export const optionCustomizationRoutes = {
    list: () => "/options/customizations",
    details: (optionCustomizationType: OptionCustomizationTypeDTO, key: string) => `/options/customizations/${optionCustomizationType}/${key}`,
}
export const equipmentCustomizationRoutes = buildProductRoutes("equipment", "customizations")
export const equipmentTagRoutes = buildProductRoutes("equipment", "tags")

export const exteriorColorCustomizationRoutes = buildProductRoutes("exterior-colors", "customizations")
export const exteriorColorTagRoutes = buildProductRoutes("exterior-colors", "tags")
export const customExteriorColorsRoutes = buildProductRoutes("exterior-colors", "custom-exterior-colors")

export const interiorCustomizationRoutes = buildProductRoutes("interiors", "customizations")
export const interiorColorTagRoutes = buildProductRoutes("interiors", "color-tags")
export const customInteriorColorsRoutes = buildProductRoutes("interiors", "custom-interior-colors")

export const paintToSampleExteriorColorCustomizationRoutes = buildProductRoutes("paint-to-sample-exterior-colors", "customizations")

export const roofColorCustomizationRoutes = buildProductRoutes("roof-colors", "customizations")
export const roofColorTagRoutes = buildProductRoutes("roof-colors", "tags")

export const technicalDataRoutes = {
    details: (orderTypeKey: string, groupIndex?: number, itemIndex?: number, marketplace?: PdsMarketplace) => {
        const marketplaceParam = marketplace ? `&tab5.selectedMarketplace=${marketplace}` : ""
        const groupIndexParam = groupIndex ? `&tab5.selectedTechnicalDataMenuItem.groupIndex=${groupIndex}` : ""
        const itemIndexParam = itemIndex ? `&tab5.selectedTechnicalDataMenuItem.itemIndex=${itemIndex}` : ""
        let route = `/models/order-types/${orderTypeKey}?selectedTab=5${groupIndexParam}${itemIndexParam}${marketplaceParam}`
        technicalDataMenuItemGroups.forEach((_, index) => (route += `&tab5.menu.accordion.${index}.isExpanded=${index === groupIndex}`))
        return route
    },
}
