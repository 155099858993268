import { modelGenerationRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { ModelGenerationFilterOptionsDTO, ModelGenerationItemDTO, ModelGenerationSortField, SortDirection } from "../../../../generated/pdsapi"
import { modelGenerationApi, ModelGenerationFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { ModelGenerationListColumnsConfig } from "./columns/ModelGenerationListColumns"
import { ModelGenerationListFilter } from "./filter/ModelGenerationListFilter"
import { ModelGenerationListFilterTagsConfig } from "./filter/ModelGenerationListFilterTagsConfig"

export const ModelGenerationProductListContainerConfig: ProductListContainerConfig<
    ModelGenerationItemDTO,
    ModelGenerationFilter,
    ModelGenerationFilterOptionsDTO,
    ModelGenerationSortField
> = {
    listName: "Model Generations",
    createItemLabel: "Add new Model Generation",

    buildDetailsPath: (modelGeneration) => modelGenerationRoutes.details(modelGeneration.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<ModelGenerationFilter>({
        modelSeriesKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: ModelGenerationSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(ModelGenerationSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: modelGenerationApi.list.getPage,
    fetchFilterOptions: modelGenerationApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: ModelGenerationListFilter,
    filterTagsConfig: ModelGenerationListFilterTagsConfig,

    columnsConfig: ModelGenerationListColumnsConfig,
}

export const ModelGenerationListContainer = getProductListContainer(ModelGenerationProductListContainerConfig)
