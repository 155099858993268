import { interiorCustomizationRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import {
    InteriorCustomizationFilterOptionsDTO,
    InteriorCustomizationItemDTO,
    InteriorCustomizationSortField,
    InteriorMaterial,
    SortDirection,
} from "../../../../generated/pdsapi"
import { interiorCustomizationApi, InteriorCustomizationFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { InteriorCustomizationListColumnsConfig } from "./columns/InteriorCustomizationListColumns"
import { InteriorCustomizationListFilter } from "./filter/InteriorCustomizationListFilter"
import { InteriorCustomizationListFilterTagsConfig } from "./filter/InteriorCustomizationListFilterTagsConfig"

export const InteriorCustomizationProductListContainerConfig: ProductListContainerConfig<
    InteriorCustomizationItemDTO,
    InteriorCustomizationFilter,
    InteriorCustomizationFilterOptionsDTO,
    InteriorCustomizationSortField
> = {
    listName: "Interior Customizations",
    createItemLabel: "Add new Interior Customization",

    buildDetailsPath: (interiorCustomization) => interiorCustomizationRoutes.details(interiorCustomization.key),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<InteriorCustomizationFilter>({
        optionCodes: stringConverterGroup.optionalArray,
        modelGenerationKeys: stringConverterGroup.optionalArray,
        interiorMaterials: getEnumConverterGroup(InteriorMaterial).optionalArray,
        interiorColorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: InteriorCustomizationSortField.OPTION_CODE,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(InteriorCustomizationSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: interiorCustomizationApi.list.getPage,
    fetchFilterOptions: interiorCustomizationApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: InteriorCustomizationListFilter,
    filterTagsConfig: InteriorCustomizationListFilterTagsConfig,

    columnsConfig: InteriorCustomizationListColumnsConfig,
}

export const InteriorCustomizationListContainer = getProductListContainer(InteriorCustomizationProductListContainerConfig)
