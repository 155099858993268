import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { RoofColorItemDTO, RoofColorSortField } from "../../../../../generated/pdsapi"
import { OptionImage } from "../../../../../components/optionimage/OptionImage"

type RoofColorCatalogueColumnBuilder = ColumnBuilder<RoofColorItemDTO, RoofColorSortField>

export const RoofColorCatalogueColumnsConfig: TableColumnsConfig<RoofColorItemDTO, RoofColorSortField> = () => {
    const imageColumn: RoofColorCatalogueColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (roofColorDetails) => <OptionImage imageKey={roofColorDetails.imageKey} sizes={"100px"} />,
    }

    const orderTypeCodesColumn: RoofColorCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Order Types",
        },
        buildCellContent: (roofColorDetails) => roofColorDetails.orderTypeCodes.join(", "),
    }

    const modelYearColumn: RoofColorCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Model Year",
        },
        buildCellContent: (roofColorDetails) => roofColorDetails.modelYear.toString(),
    }

    const optionCodeColumn: RoofColorCatalogueColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (roofColorDetails) => roofColorDetails.optionCode,
    }

    const nameColumn: RoofColorCatalogueColumnBuilder = {
        grow: 8,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (roofColorDetails) =>
            roofColorDetails.hexCode ? (
                <NamedColorTile label={roofColorDetails.name ?? "-"} hexCode={roofColorDetails.hexCode} />
            ) : (
                (roofColorDetails.name ?? "-")
            ),
    }

    const colorTagColumn: RoofColorCatalogueColumnBuilder = {
        grow: 5,
        headerCellProps: {
            content: "Color Tag",
        },
        buildCellContent: (roofColorDetails) =>
            roofColorDetails.colorTag ? <NamedColorTile hexCode={roofColorDetails.colorTag.hexCode} label={roofColorDetails.colorTag.label} /> : "-",
    }

    const modelGenerationColumn: RoofColorCatalogueColumnBuilder = {
        grow: 5,
        headerCellProps: {
            content: "Model Generation",
        },
        buildCellContent: (roofColorDetails) => roofColorDetails.modelGenerationName ?? "-",
    }

    return [imageColumn, orderTypeCodesColumn, modelYearColumn, optionCodeColumn, nameColumn, colorTagColumn, modelGenerationColumn]
}
