/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DerivativeCategoryFilterOptionsDTO,
    DerivativeCategoryFilterOptionsDTOFromJSON,
    DerivativeCategoryFilterOptionsDTOToJSON,
    DerivativeCategorySortField,
    DerivativeCategorySortFieldFromJSON,
    DerivativeCategorySortFieldToJSON,
    PageDTODerivativeCategoryItemDTO,
    PageDTODerivativeCategoryItemDTOFromJSON,
    PageDTODerivativeCategoryItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetDerivativeCategoriesPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: DerivativeCategorySortField;
    sortDirection: SortDirection;
    search?: string;
    modelSeriesKeys?: Array<string>;
}

export interface GetDerivativeCategoryFilterOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class DerivativeCategoryListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get page of DerivativeCategories
     */
    async getDerivativeCategoriesPageRaw(requestParameters: GetDerivativeCategoriesPageRequest): Promise<runtime.ApiResponse<PageDTODerivativeCategoryItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getDerivativeCategoriesPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getDerivativeCategoriesPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getDerivativeCategoriesPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getDerivativeCategoriesPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getDerivativeCategoriesPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelSeriesKeys) {
            queryParameters['modelSeriesKeys'] = requestParameters.modelSeriesKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTODerivativeCategoryItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of DerivativeCategories
     */
    async getDerivativeCategoriesPage(requestParameters: GetDerivativeCategoriesPageRequest): Promise<PageDTODerivativeCategoryItemDTO> {
        const response = await this.getDerivativeCategoriesPageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get options to filter DerivativeCategories
     */
    async getDerivativeCategoryFilterOptionsRaw(requestParameters: GetDerivativeCategoryFilterOptionsRequest): Promise<runtime.ApiResponse<DerivativeCategoryFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getDerivativeCategoryFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeCategoryFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter DerivativeCategories
     */
    async getDerivativeCategoryFilterOptions(requestParameters: GetDerivativeCategoryFilterOptionsRequest): Promise<DerivativeCategoryFilterOptionsDTO> {
        const response = await this.getDerivativeCategoryFilterOptionsRaw(requestParameters);
        return await response.value();
    }

}
