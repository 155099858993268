/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    BaseInformationDTO,
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomizationVideoUpdateDTO,
    CustomizationVideoUpdateDTOFromJSON,
    CustomizationVideoUpdateDTOToJSON,
    OptionCustomizationAttributesDTOObject,
    OptionCustomizationAttributesDTOObjectFromJSON,
    OptionCustomizationAttributesDTOObjectToJSON,
    OptionCustomizationAttributesUpdateDTOObjectOptionCustomization,
    OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationFromJSON,
    OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationToJSON,
    OptionCustomizationLinksDTO,
    OptionCustomizationLinksDTOFromJSON,
    OptionCustomizationLinksDTOToJSON,
    OptionCustomizationTypeDTO,
    OptionCustomizationTypeDTOFromJSON,
    OptionCustomizationTypeDTOToJSON,
    OptionCustomizationValuesDTOMapStringLocalizationsItemDTO,
    OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSON,
    OptionCustomizationValuesDTOMapStringLocalizationsItemDTOToJSON,
    OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO,
    OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOFromJSON,
    OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOToJSON,
    SimpleApiError,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface DeleteOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
}

export interface GetAttributesOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetLinksOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
}

export interface GetLocalizationsOfNameOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
}

export interface GetLocalizationsOfVideoOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
}

export interface GetVehicleImagesOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    limit?: number;
}

export interface UpdateAttributesOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    optionCustomizationAttributesUpdateDTOObjectOptionCustomization: OptionCustomizationAttributesUpdateDTOObjectOptionCustomization;
}

export interface UpdateDescriptionOfDefaultValueOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfDefaultValueOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateVideoOfDefaultValueOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

export interface UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    conditionalValueKey: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

/**
 * 
 */
export class OptionCustomizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a OptionCustomization by a given key
     */
    async deleteOptionCustomizationRaw(requestParameters: DeleteOptionCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling deleteOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a OptionCustomization by a given key
     */
    async deleteOptionCustomization(requestParameters: DeleteOptionCustomizationRequest): Promise<void> {
        await this.deleteOptionCustomizationRaw(requestParameters);
    }

    /**
     * Get attributes of OptionCustomization with given key
     */
    async getAttributesOfOptionCustomizationRaw(requestParameters: GetAttributesOfOptionCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationAttributesDTOObject>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getAttributesOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttributesOfOptionCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getAttributesOfOptionCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/attributes`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationAttributesDTOObjectFromJSON(jsonValue));
    }

    /**
     * Get attributes of OptionCustomization with given key
     */
    async getAttributesOfOptionCustomization(requestParameters: GetAttributesOfOptionCustomizationRequest): Promise<OptionCustomizationAttributesDTOObject> {
        const response = await this.getAttributesOfOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get baseInformation of OptionCustomization with given key
     */
    async getBaseInformationOfOptionCustomizationRaw(requestParameters: GetBaseInformationOfOptionCustomizationRequest): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getBaseInformationOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getBaseInformationOfOptionCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getBaseInformationOfOptionCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/base-information`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of OptionCustomization with given key
     */
    async getBaseInformationOfOptionCustomization(requestParameters: GetBaseInformationOfOptionCustomizationRequest): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get links of OptionCustomization with given key
     */
    async getLinksOfOptionCustomizationRaw(requestParameters: GetLinksOfOptionCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationLinksDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getLinksOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLinksOfOptionCustomization.');
        }

        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getLinksOfOptionCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/links`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of OptionCustomization with given key
     */
    async getLinksOfOptionCustomization(requestParameters: GetLinksOfOptionCustomizationRequest): Promise<OptionCustomizationLinksDTO> {
        const response = await this.getLinksOfOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of OptionCustomization with given key
     */
    async getLocalizationsOfDescriptionOfOptionCustomizationRaw(requestParameters: GetLocalizationsOfDescriptionOfOptionCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getLocalizationsOfDescriptionOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfDescriptionOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/description/localizations`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfDescription of OptionCustomization with given key
     */
    async getLocalizationsOfDescriptionOfOptionCustomization(requestParameters: GetLocalizationsOfDescriptionOfOptionCustomizationRequest): Promise<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO> {
        const response = await this.getLocalizationsOfDescriptionOfOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfName of OptionCustomization with given key
     */
    async getLocalizationsOfNameOfOptionCustomizationRaw(requestParameters: GetLocalizationsOfNameOfOptionCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getLocalizationsOfNameOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfNameOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/name/localizations`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfName of OptionCustomization with given key
     */
    async getLocalizationsOfNameOfOptionCustomization(requestParameters: GetLocalizationsOfNameOfOptionCustomizationRequest): Promise<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO> {
        const response = await this.getLocalizationsOfNameOfOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get localizationsOfVideo of OptionCustomization with given key
     */
    async getLocalizationsOfVideoOfOptionCustomizationRaw(requestParameters: GetLocalizationsOfVideoOfOptionCustomizationRequest): Promise<runtime.ApiResponse<OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getLocalizationsOfVideoOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getLocalizationsOfVideoOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/video/localizations`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfVideo of OptionCustomization with given key
     */
    async getLocalizationsOfVideoOfOptionCustomization(requestParameters: GetLocalizationsOfVideoOfOptionCustomizationRequest): Promise<OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO> {
        const response = await this.getLocalizationsOfVideoOfOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get vehicleImages of OptionCustomization with given key
     */
    async getVehicleImagesOfOptionCustomizationRaw(requestParameters: GetVehicleImagesOfOptionCustomizationRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getVehicleImagesOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getVehicleImagesOfOptionCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/vehicle-images`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Get vehicleImages of OptionCustomization with given key
     */
    async getVehicleImagesOfOptionCustomization(requestParameters: GetVehicleImagesOfOptionCustomizationRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesOfOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attributes of an existing OptionCustomization
     */
    async updateAttributesOfOptionCustomizationRaw(requestParameters: UpdateAttributesOfOptionCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateAttributesOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateAttributesOfOptionCustomization.');
        }

        if (requestParameters.optionCustomizationAttributesUpdateDTOObjectOptionCustomization === null || requestParameters.optionCustomizationAttributesUpdateDTOObjectOptionCustomization === undefined) {
            throw new runtime.RequiredError('optionCustomizationAttributesUpdateDTOObjectOptionCustomization','Required parameter requestParameters.optionCustomizationAttributesUpdateDTOObjectOptionCustomization was null or undefined when calling updateAttributesOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/attributes`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationToJSON(requestParameters.optionCustomizationAttributesUpdateDTOObjectOptionCustomization),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing OptionCustomization
     */
    async updateAttributesOfOptionCustomization(requestParameters: UpdateAttributesOfOptionCustomizationRequest): Promise<void> {
        await this.updateAttributesOfOptionCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing OptionCustomization
     */
    async updateDescriptionOfDefaultValueOfOptionCustomizationRaw(requestParameters: UpdateDescriptionOfDefaultValueOfOptionCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateDescriptionOfDefaultValueOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfDefaultValueOfOptionCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfDefaultValueOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/default-value/description`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing OptionCustomization
     */
    async updateDescriptionOfDefaultValueOfOptionCustomization(requestParameters: UpdateDescriptionOfDefaultValueOfOptionCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfDefaultValueOfOptionCustomizationRaw(requestParameters);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/conditional-values/{conditionalValueKey}/value/description`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest): Promise<void> {
        await this.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfDefaultValue of an existing OptionCustomization
     */
    async updateNameOfDefaultValueOfOptionCustomizationRaw(requestParameters: UpdateNameOfDefaultValueOfOptionCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateNameOfDefaultValueOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfDefaultValueOfOptionCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfDefaultValueOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/default-value/name`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfDefaultValue of an existing OptionCustomization
     */
    async updateNameOfDefaultValueOfOptionCustomization(requestParameters: UpdateNameOfDefaultValueOfOptionCustomizationRequest): Promise<void> {
        await this.updateNameOfDefaultValueOfOptionCustomizationRaw(requestParameters);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/conditional-values/{conditionalValueKey}/value/name`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest): Promise<void> {
        await this.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters);
    }

    /**
     * Updates videoOfDefaultValue of an existing OptionCustomization
     */
    async updateVideoOfDefaultValueOfOptionCustomizationRaw(requestParameters: UpdateVideoOfDefaultValueOfOptionCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateVideoOfDefaultValueOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateVideoOfDefaultValueOfOptionCustomization.');
        }

        if (requestParameters.customizationVideoUpdateDTO === null || requestParameters.customizationVideoUpdateDTO === undefined) {
            throw new runtime.RequiredError('customizationVideoUpdateDTO','Required parameter requestParameters.customizationVideoUpdateDTO was null or undefined when calling updateVideoOfDefaultValueOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/default-value/video`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters.customizationVideoUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfDefaultValue of an existing OptionCustomization
     */
    async updateVideoOfDefaultValueOfOptionCustomization(requestParameters: UpdateVideoOfDefaultValueOfOptionCustomizationRequest): Promise<void> {
        await this.updateVideoOfDefaultValueOfOptionCustomizationRaw(requestParameters);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.conditionalValueKey === null || requestParameters.conditionalValueKey === undefined) {
            throw new runtime.RequiredError('conditionalValueKey','Required parameter requestParameters.conditionalValueKey was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        if (requestParameters.customizationVideoUpdateDTO === null || requestParameters.customizationVideoUpdateDTO === undefined) {
            throw new runtime.RequiredError('customizationVideoUpdateDTO','Required parameter requestParameters.customizationVideoUpdateDTO was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/conditional-values/{conditionalValueKey}/value/video`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters.conditionalValueKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters.customizationVideoUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest): Promise<void> {
        await this.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters);
    }

}
