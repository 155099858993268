/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseNameDTO,
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue
 */
export interface OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue
     */
    description: BaseNameDTO;
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON(json: any): OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue {
    return OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
    };
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON(value?: OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BaseNameDTOToJSON(value.name),
        'description': BaseNameDTOToJSON(value.description),
    };
}


