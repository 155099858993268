import { SortableListCell } from "./SortableListCell"
import { color } from "@porsche-design-system/utilities"
import { FC } from "react"
import styled from "styled-components"
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable"

export interface SortableListItem {
    // identifier of item
    key: string
    // displayed name
    label: string
}

interface SortableListProps {
    items: SortableListItem[]
    onChange: (value: SortableListItem[]) => void
}

export const SortableList: FC<SortableListProps> = (props) => {
    const handleSortEnd = (event: DragEndEvent) => {
        const { active, over } = event

        if (over && active.id !== over.id) {
            const update = arrayMove(
                props.items,
                props.items.findIndex((item) => item.key === active.id),
                props.items.findIndex((item) => item.key === over.id)
            )
            console.log(update)
            props.onChange(update)
        }
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    return (
        <SortableListWrapper>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleSortEnd}>
                <SortableContext items={props.items.map((item) => item.key)} strategy={verticalListSortingStrategy}>
                    {props.items.map((item) => (
                        <SortableListCell key={item.label} item={item} />
                    ))}
                </SortableContext>
            </DndContext>
        </SortableListWrapper>
    )
}

const SortableListWrapper = styled.ul`
    background: ${color.background.surface};
    padding: 0;
    margin: 0;
    border: 1px solid ${color.neutralContrast.low};
`
