import { TableFilterTagsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../../../components/tag/Tag"
import { OrderTypeExteriorColorFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeExteriorColorsFilter } from "../../../../../../../pdsapi"
import { findOption } from "../../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const OrderTypeExteriorColorsCardFilterTagsConfig: TableFilterTagsConfig<OrderTypeExteriorColorsFilter, OrderTypeExteriorColorFilterOptionsDTO> = ({
    filterOptions,
    filter,
    setFilter,
}) => {
    const filterTags: JSX.Element[] = []

    filter.colorTypes?.forEach((colorType) => {
        filterTags.push(
            <Tag
                key={`filterTag_colorType_${colorType}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newColorTypes = filter.colorTypes?.filter((key) => colorType !== key)
                        return {
                            ...filter,
                            colorTypes: newColorTypes?.length !== 0 ? newColorTypes : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.colorTypes, colorType)?.label ?? colorType}
            </Tag>
        )
    })

    filter.tagKeys?.forEach((exteriorColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_exteriorColorTagKey_${exteriorColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newExteriorColorTagKeys = filter.tagKeys?.filter((key) => exteriorColorTagKey !== key)
                        return {
                            ...filter,
                            tagKeys: newExteriorColorTagKeys?.length !== 0 ? newExteriorColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.tags, exteriorColorTagKey)?.label ?? exteriorColorTagKey}
            </Tag>
        )
    })

    return filterTags
}
