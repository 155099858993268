import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { ExteriorColorCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { ExteriorColorCustomizationFilter } from "../../../../../pdsapi"
import { findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const ExteriorColorCustomizationListFilterTagsConfig: TableFilterTagsConfig<
    ExteriorColorCustomizationFilter,
    ExteriorColorCustomizationFilterOptionsDTO
> = ({ filter, setFilter, filterOptions }) => {
    const filterTags: JSX.Element[] = []

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelGenerations, modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.exteriorColorTagKeys?.forEach((exteriorColorTagKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_exteriorColorTagKey_${exteriorColorTagKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newExteriorColorTagKeys = filter.exteriorColorTagKeys?.filter((key) => exteriorColorTagKey !== key)
                        return {
                            ...filter,
                            exteriorColorTagKeys: newExteriorColorTagKeys?.length !== 0 ? newExteriorColorTagKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.exteriorColorTags, exteriorColorTagKey)?.label ?? exteriorColorTagKey}
            </Tag>
        )
    })

    return filterTags
}
