/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTypeOptionItemDTOPaintToSampleExteriorColor,
    OrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSON,
    OrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSONTyped,
    OrderTypeOptionItemDTOPaintToSampleExteriorColorToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor
 */
export interface PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor {
    /**
     * 
     * @type {number}
     * @memberof PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<OrderTypeOptionItemDTOPaintToSampleExteriorColor>}
     * @memberof PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor
     */
    content: Array<OrderTypeOptionItemDTOPaintToSampleExteriorColor>;
}

export function PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSON(json: any): PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor {
    return PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(OrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSON)),
    };
}

export function PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColorToJSON(value?: PageDTOOrderTypeOptionItemDTOPaintToSampleExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'content': ((value.content as Array<any>).map(OrderTypeOptionItemDTOPaintToSampleExteriorColorToJSON)),
    };
}


