import { exteriorColorTagRoutes } from "../../../../../../common/routes"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    ExteriorColorType,
    OldOptionCustomizationAttributesDTOExteriorColorCustomizationAttributesValueDTO,
    OldOptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO,
} from "../../../../../../generated/pdsapi"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import {
    OptionCustomizationDetailsAttributesCardContent,
    OptionCustomizationValueContent,
} from "../../../../../../components/optioncustomization/OptionCustomizationDetailsAttributesCardContent"

type AttributesDTO = OldOptionCustomizationAttributesDTOExteriorColorCustomizationAttributesValueDTO
type Value = AttributesDTO["defaultValue"]
type AttributesUpdateDTO = OldOptionCustomizationAttributesUpdateDTOExteriorColorCustomizationAttributesValueUpdateDTO
type ValueUpdate = AttributesUpdateDTO["defaultValue"]

export const ExteriorColorCustomizationDetailsAttributesCardContent: FC<ProductDetailsCardContentProps<AttributesDTO, AttributesUpdateDTO>> = (props) => (
    <OptionCustomizationDetailsAttributesCardContent
        {...props}
        convertValueToValueUpdate={convertValueToValueUpdate}
        CustomizationValueContent={CustomizationValueContent}
    />
)

const convertValueToValueUpdate = (value: Value): ValueUpdate => ({
    name: value.name,
    description: value.description,
    hexCode: value.hexCode,
    isCustomColor: value.isCustomColor,
    exteriorColorTagKey: value.tag.value?.key,
    exteriorColorType: value.exteriorColorType.value?.key ?? ("" as ExteriorColorType),
})

const CustomizationValueContent: OptionCustomizationValueContent<Value, ValueUpdate> = ({ getValue, property, formDisplayData }) => {
    const attributes = formDisplayData.data
    const value = attributes && getValue(attributes)
    const [hexCode, setHexCode] = useSyncState(value?.hexCode)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={property.name}
                getViewProps={(data) => ({
                    value: getValue(data).name,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).name,
                    optional: true,
                })}
            />
            <DetailsDefaultLocalizationsFormRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={property.description}
                getViewProps={(data) => ({
                    value: getValue(data).description,
                })}
                getEditProps={(data) => ({
                    value: getValue(data).description,
                    optional: true,
                })}
            />
            <RowGroupWrapper label={"Color"}>
                <FormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={property.hexCode}
                    FormElement={getColorPickerFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={() => ({
                        hexValue: hexCode,
                        disableGlossy: true,
                    })}
                    getEditProps={() => ({
                        hexValue: hexCode,
                        disableGlossy: true,
                        onChange: setHexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Exterior Color Tag"}
                    formDisplayData={formDisplayData}
                    field={property.exteriorColorTagKey}
                    FormElement={getTagFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => {
                        const tag = getValue(data).tag.value
                        return {
                            tag: tag && toTagOption(tag, exteriorColorTagRoutes.details),
                        }
                    }}
                    getEditProps={(data) => ({
                        value: getValue(data).tag.value?.key ?? "",
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...getValue(data).tag.options.map((tag) => toOption(tag)),
                        ],
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Exterior Color Type"}
                    formDisplayData={formDisplayData}
                    field={property.exteriorColorType}
                    FormElement={getSelectTextFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: getValue(data).exteriorColorType.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        value: getValue(data).exteriorColorType.value?.key ?? "",
                        options: [{ value: "", label: "-" }, ...getValue(data).exteriorColorType.options.map(toOption)],
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <FormElementWrapper
                    label={"Custom Color"}
                    formDisplayData={formDisplayData}
                    field={property.isCustomColor}
                    FormElement={getBoolFormElement<AttributesDTO, AttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: getValue(data).isCustomColor,
                    })}
                    getEditProps={(data) => ({
                        trueMessage: "Yes",
                        falseMessage: "No",
                        value: getValue(data).isCustomColor,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}
