import { paintToSampleExteriorColorRoutes } from "../../../../common/routes"
import { paintToSampleExteriorColorCustomizationApi } from "../../../../pdsapi"
import { getCustomizationDetailsContainer } from "../../../../viewtemplates/details/CustomizationDetailsContainer"
import { ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLinkedOptionsCard } from "../../../../viewtemplates/details/cards/linkedoptionscard/DetailsLinkedOptionsCard"
import { DetailsVehicleImagesCard } from "../../../../viewtemplates/details/cards/vehicleimagescard/DetailsVehicleImagesCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { DetailsOptionCustomizationLocalizationTab } from "../../../../viewtemplates/details/tabs/optioncustomizationlocalizationtab/DetailsOptionCustomizationLocalizationTab"
import { PaintToSampleExteriorColorCustomizationDetailsAttributesCardContent } from "./data/attributes/PaintToSampleExteriorColorCustomizationDetailsAttributesCardContent"
import { CustomizationVideosTab } from "../../../../viewtemplates/details/tabs/optionscustomizationvideotab/CustomizationVideosTab"

export const PaintToSampleExteriorColorCustomizationProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: paintToSampleExteriorColorCustomizationApi.details.getBaseInformation,
    deleteByKey: paintToSampleExteriorColorCustomizationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={paintToSampleExteriorColorCustomizationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            paintToSampleExteriorColorCustomizationApi.details.updateAttributes({
                                key,
                                oldOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorCustomizationAttributesValueUpdateDTO: update,
                            })
                        }
                        Content={PaintToSampleExteriorColorCustomizationDetailsAttributesCardContent}
                    />
                    <DetailsLinkedOptionsCard
                        {...props}
                        getData={paintToSampleExteriorColorCustomizationApi.details.getLinks}
                        getRoute={paintToSampleExteriorColorRoutes.details}
                    />
                    <DetailsVehicleImagesCard {...props} load={paintToSampleExteriorColorCustomizationApi.details.getVehicleImages} />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={paintToSampleExteriorColorCustomizationApi.details.getName}
                    updateDefaultValue={paintToSampleExteriorColorCustomizationApi.details.updateDefaultName}
                    updateConditionalValue={paintToSampleExteriorColorCustomizationApi.details.updateConditionalValueName}
                />
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <DetailsOptionCustomizationLocalizationTab
                    {...props}
                    getData={paintToSampleExteriorColorCustomizationApi.details.getDescription}
                    updateDefaultValue={paintToSampleExteriorColorCustomizationApi.details.updateDefaultDescription}
                    updateConditionalValue={paintToSampleExteriorColorCustomizationApi.details.updateConditionalValueDescription}
                />
            ),
        },
        {
            label: "Videos",
            Content: (props) => (
                <CustomizationVideosTab
                    {...props}
                    getData={paintToSampleExteriorColorCustomizationApi.details.getVideos}
                    updateDefaultValue={paintToSampleExteriorColorCustomizationApi.details.updateDefaultVideo}
                    updateConditionalValue={paintToSampleExteriorColorCustomizationApi.details.updateConditionalVideo}
                />
            ),
        },
    ],
}

export const PaintToSampleExteriorColorCustomizationDetailsContainer = getCustomizationDetailsContainer(
    PaintToSampleExteriorColorCustomizationProductDetailsContainerConfig
)
