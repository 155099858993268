import { SpacedItems } from "../../layouts/spaceditems/SpacedItems"
import { HeadlineVariant, PFlex, PFlexItem, PHeadline } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, JSX } from "react"

export interface HeadlineWithActionsProps {
    variant: HeadlineVariant
    label: string
    rightActions?: JSX.Element[]
    rightActionsSpacing?: string
}

export const HeadlineWithActions: FC<HeadlineWithActionsProps> = (props) => (
    <PFlex justifyContent={"space-between"} alignItems={"center"} wrap={"nowrap"}>
        <PFlexItem width={"two-thirds"}>
            <PHeadline variant={props.variant} ellipsis={true}>
                {props.label}
            </PHeadline>
        </PFlexItem>

        <PFlexItem>
            <SpacedItems direction={"row"} itemSpacing={props.rightActionsSpacing ?? spacing[24]} justifyContent={"flex-end"}>
                {props.rightActions}
            </SpacedItems>
        </PFlexItem>
    </PFlex>
)
