import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { DerivativeFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { DerivativeFilter } from "../../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const DerivativeListFilterTagsConfig: TableFilterTagsConfig<DerivativeFilter, DerivativeFilterOptionsDTO> = ({ filter, setFilter, filterOptions }) => {
    const filterTags: JSX.Element[] = []

    filter.modelSeriesKeys?.forEach((modelSeriesKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelSeriesKey_${modelSeriesKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelSeriesKeys = filter.modelSeriesKeys?.filter((key) => modelSeriesKey !== key)
                        return {
                            ...filter,
                            modelSeriesKeys: newModelSeriesKeys?.length !== 0 ? newModelSeriesKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelSeries, modelSeriesKey)?.label ?? modelSeriesKey}
            </Tag>
        )
    })

    filter.derivativeCategoryKeys?.forEach((derivativeCategoryKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_derivativeCategoryKey_${derivativeCategoryKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newDerivativeCategoryKeys = filter.derivativeCategoryKeys?.filter((key) => derivativeCategoryKey !== key)
                        return {
                            ...filter,
                            derivativeCategoryKeys: newDerivativeCategoryKeys?.length !== 0 ? newDerivativeCategoryKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.modelSeriesRelatedDerivativeCategories, derivativeCategoryKey)?.label ?? derivativeCategoryKey}
            </Tag>
        )
    })

    filter.bodyTypes?.forEach((bodyType) => {
        filterTags.push(
            <Tag
                key={`filterTag_bodyType_${bodyType}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newBodyTypes = filter.bodyTypes?.filter((key) => bodyType !== key)
                        return {
                            ...filter,
                            bodyTypes: newBodyTypes?.length !== 0 ? newBodyTypes : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.bodyTypes, bodyType)?.label ?? bodyType}
            </Tag>
        )
    })

    return filterTags
}
