/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationAttributesDTOExteriorColorInternal,
    OptionCustomizationAttributesDTOExteriorColorInternalFromJSONTyped,
    OptionCustomizationAttributesDTOExteriorColorInternalToJSON,
} from './';

/**
 * @type OptionCustomizationAttributesDTOExteriorColor
 * 
 * @export
 */
export type OptionCustomizationAttributesDTOExteriorColor = { type: 'ExteriorColor' } & OptionCustomizationAttributesDTOExteriorColorInternal;

export function OptionCustomizationAttributesDTOExteriorColorFromJSON(json: any): OptionCustomizationAttributesDTOExteriorColor {
    return OptionCustomizationAttributesDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOExteriorColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionCustomizationAttributesDTOExteriorColorInternalFromJSONTyped(json, true), type: 'ExteriorColor'};
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOExteriorColor exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationAttributesDTOExteriorColorToJSON(value?: OptionCustomizationAttributesDTOExteriorColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionCustomizationAttributesDTOExteriorColorInternalToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOExteriorColor exists with 'type=${value['type']}'`);
    }
}

