import { JSX } from "react"
import { TableFilterTagsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { OrderTypeOptionFilter } from "../../../../../../../pdsapi"
import { OrderTypeOptionFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { Tag } from "../../../../../../../components/tag/Tag"
import { findOption } from "../../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"

export const OrderTypeOptionItemsCardFilterTagsConfig: TableFilterTagsConfig<OrderTypeOptionFilter, OrderTypeOptionFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const filterTags: JSX.Element[] = []

    filter.types?.forEach((type) => {
        filterTags.push(
            <Tag
                key={`filterTag_type_${type}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newTypes = filter.types?.filter((t) => type !== t)
                        return {
                            ...filter,
                            types: newTypes?.length !== 0 ? newTypes : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.types, type)?.label ?? type}
            </Tag>
        )
    })

    return filterTags
}
