import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { Tag } from "../../../../../components/tag/Tag"
import { OrderTypeFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OrderTypeFilter } from "../../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { JSX } from "react"

export const OrderTypeListFilterTagsConfig: TableFilterTagsConfig<OrderTypeFilter, OrderTypeFilterOptionsDTO> = ({ filter, setFilter, filterOptions }) => {
    const filterTags: JSX.Element[] = []

    filter.derivativeKeys?.forEach((derivativeKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_derivativeKey_${derivativeKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newDerivativeKeys = filter.derivativeKeys?.filter((key) => derivativeKey !== key)
                        return {
                            ...filter,
                            derivativeKeys: newDerivativeKeys?.length !== 0 ? newDerivativeKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.modelSeriesRelatedDerivatives, derivativeKey)?.label ?? derivativeKey}
            </Tag>
        )
    })

    filter.modelGenerationKeys?.forEach((modelGenerationKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelGenerationKey_${modelGenerationKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelGenerationKeys = filter.modelGenerationKeys?.filter((key) => modelGenerationKey !== key)
                        return {
                            ...filter,
                            modelGenerationKeys: newModelGenerationKeys?.length !== 0 ? newModelGenerationKeys : undefined,
                        }
                    })
                }
            >
                {findGroupedOption(filterOptions?.modelSeriesRelatedModelGenerations, modelGenerationKey)?.label ?? modelGenerationKey}
            </Tag>
        )
    })

    filter.modelSeriesKeys?.forEach((modelSeriesKey) => {
        filterTags.push(
            <Tag
                key={`filterTag_modelSeriesKey_${modelSeriesKey}`}
                onRemove={() =>
                    setFilter((filter) => {
                        const newModelSeriesKeys = filter.modelSeriesKeys?.filter((key) => modelSeriesKey !== key)
                        return {
                            ...filter,
                            modelSeriesKeys: newModelSeriesKeys?.length !== 0 ? newModelSeriesKeys : undefined,
                        }
                    })
                }
            >
                {findOption(filterOptions?.modelSeries, modelSeriesKey)?.label ?? modelSeriesKey}
            </Tag>
        )
    })

    return filterTags
}
