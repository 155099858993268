/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExteriorColorFilterOptionsDTO,
    ExteriorColorFilterOptionsDTOFromJSON,
    ExteriorColorFilterOptionsDTOToJSON,
    ExteriorColorSortField,
    ExteriorColorSortFieldFromJSON,
    ExteriorColorSortFieldToJSON,
    ExteriorColorType,
    ExteriorColorTypeFromJSON,
    ExteriorColorTypeToJSON,
    PageDTOExteriorColorItemDTO,
    PageDTOExteriorColorItemDTOFromJSON,
    PageDTOExteriorColorItemDTOToJSON,
    SortDirection,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetExteriorColorFilterOptionsRequest {
    languageTag: string;
}

export interface GetExteriorColorsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: ExteriorColorSortField;
    sortDirection: SortDirection;
    search?: string;
    modelGenerationKeys?: Array<string>;
    modelYears?: Array<number>;
    orderTypeKeys?: Array<string>;
    exteriorColorTypes?: Array<ExteriorColorType>;
    colorTagKeys?: Array<string>;
}

/**
 * 
 */
export class ExteriorColorCatalogueWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter ExteriorColors
     */
    async getExteriorColorFilterOptionsRaw(requestParameters: GetExteriorColorFilterOptionsRequest): Promise<runtime.ApiResponse<ExteriorColorFilterOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getExteriorColorFilterOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExteriorColorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter ExteriorColors
     */
    async getExteriorColorFilterOptions(requestParameters: GetExteriorColorFilterOptionsRequest): Promise<ExteriorColorFilterOptionsDTO> {
        const response = await this.getExteriorColorFilterOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get page of ExteriorColors
     */
    async getExteriorColorsPageRaw(requestParameters: GetExteriorColorsPageRequest): Promise<runtime.ApiResponse<PageDTOExteriorColorItemDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getExteriorColorsPage.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getExteriorColorsPage.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getExteriorColorsPage.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getExteriorColorsPage.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getExteriorColorsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.modelYears) {
            queryParameters['modelYears'] = requestParameters.modelYears;
        }

        if (requestParameters.orderTypeKeys) {
            queryParameters['orderTypeKeys'] = requestParameters.orderTypeKeys;
        }

        if (requestParameters.exteriorColorTypes) {
            queryParameters['exteriorColorTypes'] = requestParameters.exteriorColorTypes;
        }

        if (requestParameters.colorTagKeys) {
            queryParameters['colorTagKeys'] = requestParameters.colorTagKeys;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/exterior-color/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOExteriorColorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of ExteriorColors
     */
    async getExteriorColorsPage(requestParameters: GetExteriorColorsPageRequest): Promise<PageDTOExteriorColorItemDTO> {
        const response = await this.getExteriorColorsPageRaw(requestParameters);
        return await response.value();
    }

}
