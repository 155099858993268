/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoofColorCustomizationSortField {
    OPTION_CODE = 'OPTION_CODE'
}

export function RoofColorCustomizationSortFieldFromJSON(json: any): RoofColorCustomizationSortField {
    return RoofColorCustomizationSortFieldFromJSONTyped(json, false);
}

export function RoofColorCustomizationSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoofColorCustomizationSortField {
    return json as RoofColorCustomizationSortField;
}

export function RoofColorCustomizationSortFieldToJSON(value?: RoofColorCustomizationSortField | null): any {
    return value as any;
}

