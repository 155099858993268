/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionLocalizationItemDTO,
    OptionLocalizationItemDTOFromJSON,
    OptionLocalizationItemDTOFromJSONTyped,
    OptionLocalizationItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionDTOBaseLocalization
 */
export interface OptionDTOBaseLocalization {
    /**
     * 
     * @type {OptionLocalizationItemDTO}
     * @memberof OptionDTOBaseLocalization
     */
    en: OptionLocalizationItemDTO;
    /**
     * 
     * @type {OptionLocalizationItemDTO}
     * @memberof OptionDTOBaseLocalization
     */
    de: OptionLocalizationItemDTO;
}

export function OptionDTOBaseLocalizationFromJSON(json: any): OptionDTOBaseLocalization {
    return OptionDTOBaseLocalizationFromJSONTyped(json, false);
}

export function OptionDTOBaseLocalizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOBaseLocalization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'en': OptionLocalizationItemDTOFromJSON(json['en']),
        'de': OptionLocalizationItemDTOFromJSON(json['de']),
    };
}

export function OptionDTOBaseLocalizationToJSON(value?: OptionDTOBaseLocalization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'en': OptionLocalizationItemDTOToJSON(value.en),
        'de': OptionLocalizationItemDTOToJSON(value.de),
    };
}


