/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationApiError,
    AuthorizationApiErrorFromJSON,
    AuthorizationApiErrorToJSON,
    CreateResponseDTO,
    CreateResponseDTOFromJSON,
    CreateResponseDTOToJSON,
    OptionCustomizationCreateOptionsDTO,
    OptionCustomizationCreateOptionsDTOFromJSON,
    OptionCustomizationCreateOptionsDTOToJSON,
    OptionCustomizationCreateRequestDTO,
    OptionCustomizationCreateRequestDTOFromJSON,
    OptionCustomizationCreateRequestDTOToJSON,
    OptionCustomizationTypeDTO,
    OptionCustomizationTypeDTOFromJSON,
    OptionCustomizationTypeDTOToJSON,
    SimpleOrValidationApiError,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResult,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models';

export interface CreateOptionCustomizationRequest {
    optionCustomizationCreateRequestDTO: OptionCustomizationCreateRequestDTO;
}

export interface GetOptionCustomizationCreateOptionsRequest {
    languageTag: string;
}

export interface GetVehicleImagesForOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    optionCode: string;
    modelGenerationKeys: Array<string>;
    limit?: number;
}

/**
 * 
 */
export class OptionCustomizationCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new OptionCustomization
     */
    async createOptionCustomizationRaw(requestParameters: CreateOptionCustomizationRequest): Promise<runtime.ApiResponse<CreateResponseDTO>> {
        if (requestParameters.optionCustomizationCreateRequestDTO === null || requestParameters.optionCustomizationCreateRequestDTO === undefined) {
            throw new runtime.RequiredError('optionCustomizationCreateRequestDTO','Required parameter requestParameters.optionCustomizationCreateRequestDTO was null or undefined when calling createOptionCustomization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionCustomizationCreateRequestDTOToJSON(requestParameters.optionCustomizationCreateRequestDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateResponseDTOFromJSON(jsonValue));
    }

    /**
     * Create a new OptionCustomization
     */
    async createOptionCustomization(requestParameters: CreateOptionCustomizationRequest): Promise<CreateResponseDTO> {
        const response = await this.createOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get select options to create OptionCustomizations
     */
    async getOptionCustomizationCreateOptionsRaw(requestParameters: GetOptionCustomizationCreateOptionsRequest): Promise<runtime.ApiResponse<OptionCustomizationCreateOptionsDTO>> {
        if (requestParameters.languageTag === null || requestParameters.languageTag === undefined) {
            throw new runtime.RequiredError('languageTag','Required parameter requestParameters.languageTag was null or undefined when calling getOptionCustomizationCreateOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageTag !== undefined) {
            queryParameters['languageTag'] = requestParameters.languageTag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create OptionCustomizations
     */
    async getOptionCustomizationCreateOptions(requestParameters: GetOptionCustomizationCreateOptionsRequest): Promise<OptionCustomizationCreateOptionsDTO> {
        const response = await this.getOptionCustomizationCreateOptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleImagesForOptionCustomizationRaw(requestParameters: GetVehicleImagesForOptionCustomizationRequest): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getVehicleImagesForOptionCustomization.');
        }

        if (requestParameters.optionCode === null || requestParameters.optionCode === undefined) {
            throw new runtime.RequiredError('optionCode','Required parameter requestParameters.optionCode was null or undefined when calling getVehicleImagesForOptionCustomization.');
        }

        if (requestParameters.modelGenerationKeys === null || requestParameters.modelGenerationKeys === undefined) {
            throw new runtime.RequiredError('modelGenerationKeys','Required parameter requestParameters.modelGenerationKeys was null or undefined when calling getVehicleImagesForOptionCustomization.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.optionCode !== undefined) {
            queryParameters['optionCode'] = requestParameters.optionCode;
        }

        if (requestParameters.modelGenerationKeys) {
            queryParameters['modelGenerationKeys'] = requestParameters.modelGenerationKeys;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/create/vehicle-images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     */
    async getVehicleImagesForOptionCustomization(requestParameters: GetVehicleImagesForOptionCustomizationRequest): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesForOptionCustomizationRaw(requestParameters);
        return await response.value();
    }

}
