import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { OptionCustomizationItemDTO, OptionCustomizationSortField } from "../../../../../generated/pdsapi"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { ExteriorColorTile } from "@finder/ui-kit"
import { spacing } from "@porsche-design-system/utilities"

type OptionCustomizationColumnBuilder = ColumnBuilder<OptionCustomizationItemDTO, OptionCustomizationSortField>

export const OptionCustomizationListColumnsConfig: TableColumnsConfig<OptionCustomizationItemDTO, OptionCustomizationSortField> = ({ sorting, setSorting }) => {
    const typeColumn: OptionCustomizationColumnBuilder = {
        width: 30,
        headerCellProps: {
            content: "Type",
        },
        buildCellContent: (optionCustomization) => {
            switch (optionCustomization.type) {
                case "ExteriorColor":
                    return "EC"
                case "PaintToSampleExteriorColor":
                    return "PTS"
            }
        },
    }
    const optionCodeColumn: OptionCustomizationColumnBuilder = {
        width: 125,
        headerCellProps: {
            content: "Option Code",
            sorting: {
                field: OptionCustomizationSortField.OPTION_CODE,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (optionCustomization) => optionCustomization.optionCode,
    }
    const modelGenerationColumn: OptionCustomizationColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Model Generations",
        },
        buildCellContent: (optionCustomization) => optionCustomization.modelGenerationNames.join(", "),
    }
    const valueColumn: OptionCustomizationColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Value",
        },
        buildCellContent: (optionCustomization) => {
            switch (optionCustomization.type) {
                case "ExteriorColor":
                    return (
                        <SpacedItems direction={"row"} itemSpacing={spacing[16]}>
                            {optionCustomization.hexCode && <ExteriorColorTile color={optionCustomization.hexCode} />}
                            {optionCustomization.exteriorColorTypeName}
                        </SpacedItems>
                    )
                case "PaintToSampleExteriorColor":
                    return ""
            }
        },
    }

    return [typeColumn, optionCodeColumn, modelGenerationColumn, valueColumn]
}
