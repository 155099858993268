import { TableColumnsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../../../components/layouts/table/Table"
import { getSortIndexColumnBuilder } from "../../../../../../../components/pagedtable/columns/columnBuilder"
import { NamedColorTile } from "../../../../../../../components/tiles/namedcolortile/NamedColorTile"
import { ExteriorColorSortField, OrderTypeOptionItemDTOExteriorColor } from "../../../../../../../generated/pdsapi"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"

type ExteriorColorItemCardColumnBuilder = ColumnBuilder<OrderTypeOptionItemDTOExteriorColor, ExteriorColorSortField>

export const OrderTypeExteriorColorsCardColumnsConfig: TableColumnsConfig<OrderTypeOptionItemDTOExteriorColor, ExteriorColorSortField> = ({
    sorting,
    setSorting,
}) => {
    const previewColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (exteriorColor) => <OptionImage imageKey={exteriorColor.imageKey} sizes={"100px"} />,
    }

    const sortIndexColumn: ExteriorColorItemCardColumnBuilder = getSortIndexColumnBuilder((exteriorColor) => exteriorColor.sortIndex, {
        field: ExteriorColorSortField.SORT_INDEX,
        value: sorting,
        setValue: setSorting,
    })

    const optionCodeColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (exteriorColor) => exteriorColor.optionCode,
    }

    const nameColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (exteriorColor) => exteriorColor.name ?? "-",
    }

    const exteriorColorTypeColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Color Type",
        },
        buildCellContent: (exteriorColor) => exteriorColor.colorTypeName ?? "-",
    }

    const colorColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Color",
        },
        buildCellContent: (exteriorColor) =>
            exteriorColor.color ? <NamedColorTile label={exteriorColor.color.label} hexCode={exteriorColor.color.hexCode} /> : "-",
    }

    const tagColumn: ExteriorColorItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Tag",
        },
        buildCellContent: (exteriorColor) => (exteriorColor.tag ? <NamedColorTile label={exteriorColor.tag.label} hexCode={exteriorColor.tag.hexCode} /> : "-"),
    }

    return [previewColumn, sortIndexColumn, optionCodeColumn, nameColumn, exteriorColorTypeColumn, colorColumn, tagColumn]
}
