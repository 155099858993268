import { Property } from "../../common/property"
import { EditFormDisplayData, LoadingFormDisplayData } from "./FormDisplayData"
import { EditProps, LoadingProps } from "./FormElementWrapper"
import { FieldValues } from "react-hook-form"
import { JSX } from "react"

export interface EditFormElement<DATA, UPDATE extends FieldValues, T, EDIT_PROPS> {
    Loading: (props: LoadingProps) => JSX.Element | null
    Edit: (props: EditProps<DATA, UPDATE, T, EDIT_PROPS>) => JSX.Element | null
}

export const EditFormElementWrapper = <DATA, UPDATE extends FieldValues, T, EDIT_PROPS>({
    label,
    formDisplayData,
    field,
    FormElement,
    getEditProps,
}: {
    label: string | undefined
    formDisplayData: LoadingFormDisplayData | EditFormDisplayData<DATA, UPDATE>
    field: Property<UPDATE, T>
    FormElement: EditFormElement<DATA, UPDATE, T, EDIT_PROPS>
    getEditProps: (data: DATA) => EDIT_PROPS
}) => {
    switch (formDisplayData.kind) {
        case "LOADING":
            return <FormElement.Loading label={label} />
        case "EDIT":
            return <FormElement.Edit label={label} field={field} {...formDisplayData} {...getEditProps(formDisplayData.data)} />
    }
}
