import { DefaultCardViewContentWrapper } from "../../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { CreateOptionResponseDTO, PaintToSampleExteriorColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { paintToSampleExteriorColorApi } from "../../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { OrderTypePaintToSampleExteriorColorCreateOptionCard } from "./option/OrderTypePaintToSampleExteriorColorCreateOptionCard"

export const OrderTypePaintToSampleExteriorColorProductCreateContainerConfig: ProductCreateContainerConfig<
    PaintToSampleExteriorColorCreateRequestDTO,
    unknown,
    CreateOptionResponseDTO
> = {
    headline: "Create new PaintToSample ExteriorColor",

    create: (item) =>
        paintToSampleExteriorColorApi.create.create({
            paintToSampleExteriorColorCreateRequestDTO: item,
        }),
    // TODO(CSPRVD-900): here we will need to fetch option codes for dropdown if we will not hardcode them
    getCreateOptions: async () => ({}),

    Content: (props) => (
        <DefaultCardViewContentWrapper>
            <OrderTypePaintToSampleExteriorColorCreateOptionCard {...props} />
        </DefaultCardViewContentWrapper>
    ),
}

export const OrderTypePaintToSampleExteriorColorCreateContainer = getProductCreateContainer(OrderTypePaintToSampleExteriorColorProductCreateContainerConfig)
