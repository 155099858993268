import {
    OptionCustomizationAttributesDTOObject,
    OptionCustomizationCreateRequestDTO,
    OptionCustomizationItemDTO,
    OptionCustomizationTypeDTO,
} from "../../../generated/pdsapi"
import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"

export type OptionCustomizationType =
    | OptionCustomizationCreateRequestDTO["type"]
    | OptionCustomizationItemDTO["type"]
    | OptionCustomizationAttributesDTOObject["type"]

const optionCustomizationTypes: Record<OptionCustomizationType, string> = {
    ExteriorColor: "Exterior Color",
    PaintToSampleExteriorColor: "Paint To Sample Exterior Color",
}

export const optionCustomizationTypeOptions: SelectOption[] = Object.entries(optionCustomizationTypes).map(([value, label]) => ({
    value,
    label,
}))

export const optionCustomizationTypeToEnum = (type: OptionCustomizationType): OptionCustomizationTypeDTO => {
    switch (type) {
        case "ExteriorColor":
            return OptionCustomizationTypeDTO.EXTERIOR_COLOR
        case "PaintToSampleExteriorColor":
            return OptionCustomizationTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR
    }
}
