import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { ModelSeriesItemDTO, ModelSeriesSortField } from "../../../../../generated/pdsapi"

type ModelSeriesColumnBuilder = ColumnBuilder<ModelSeriesItemDTO, ModelSeriesSortField>

export const ModelSeriesListColumnsConfig: TableColumnsConfig<ModelSeriesItemDTO, ModelSeriesSortField> = ({ sorting, setSorting }) => {
    const sortIndexColumn: ModelSeriesColumnBuilder = {
        width: 40,
        headerCellProps: {
            content: "#",
            sorting: {
                field: ModelSeriesSortField.SORT_INDEX,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (modelSeries) => modelSeries.sortIndex.toString(),
    }
    const nameColumn: ModelSeriesColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (modelSeries) => modelSeries.name,
    }
    const modelGenerationsColumn: ModelSeriesColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Related Model Generations",
        },
        buildCellContent: (modelSeries) => modelSeries.modelGenerationNames.join(", "),
    }

    return [sortIndexColumn, nameColumn, modelGenerationsColumn]
}
