/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OptionCustomizationAttributesUpdateDTOExteriorColor,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor,
    OptionCustomizationAttributesUpdateDTOExteriorColorFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOExteriorColorToJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorToJSON,
} from './';

/**
 * @type OptionCustomizationAttributesUpdateDTOObjectOptionCustomization
 * 
 * @export
 */
export type OptionCustomizationAttributesUpdateDTOObjectOptionCustomization = { type: 'ExteriorColor' } & OptionCustomizationAttributesUpdateDTOExteriorColor | { type: 'PaintToSampleExteriorColor' } & OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor;

export function OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationFromJSON(json: any): OptionCustomizationAttributesUpdateDTOObjectOptionCustomization {
    return OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOObjectOptionCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OptionCustomizationAttributesUpdateDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'PaintToSampleExteriorColor':
            return {...OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of OptionCustomizationAttributesUpdateDTOObjectOptionCustomization exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationToJSON(value?: OptionCustomizationAttributesUpdateDTOObjectOptionCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OptionCustomizationAttributesUpdateDTOExteriorColorToJSON(value);
        case 'PaintToSampleExteriorColor':
            return OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorToJSON(value);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesUpdateDTOObjectOptionCustomization exists with 'type=${value['type']}'`);
    }
}

