import { DefaultFormElementLoading, FormElement } from "../FormElementWrapper"
import { FieldValues } from "react-hook-form"
import { MultiTextFormElementEdit, MultiTextFormElementEditProps } from "../multitextformelement/MultiTextFormElement"
import { TagsFormElementView, TagsFormElementViewProps } from "../tagsformelement/TagsFormElement"

export const getTagFormMultiTextElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string[],
    TagsFormElementViewProps,
    MultiTextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: TagsFormElementView,
    Edit: MultiTextFormElementEdit,
})
