import { showErrorToast } from "../../../../../../common/errorToastHelper"
import { equipmentRoutes } from "../../../../../../common/routes"
import { useRequestQueue } from "../../../../../../common/useRequestQueue"
import { getEnumConverterGroup, stringConverterGroup, useURLSearchParamState } from "../../../../../../common/useURLSearchParamState"
import { Accordion } from "../../../../../../components/accordion/Accordion"
import { ColumnGroupsWrapper } from "../../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { rowGroupsSpacing } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { WidgetWrapper } from "../../../../../../components/layouts/widgetwrapper/WidgetWrapper"
import { MarketLanguageSelector } from "../../../../../../components/selectors/marketlanguageselector/MarketLanguageSelector"
import { MarketSelector } from "../../../../../../components/selectors/marketselector/MarketSelector"
import { Tag } from "../../../../../../components/tag/Tag"
import { OrderTypeStandardEquipmentDTO, PdsMarketplace } from "../../../../../../generated/pdsapi"
import { orderTypeApi } from "../../../../../../pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../viewtemplates/details/ProductDetailsContainer"
import { useToast } from "@finder/ui-kit"
import { PDivider, PHeadline, PSpinner, PText } from "@porsche-design-system/components-react"
import { color, spacing } from "@porsche-design-system/utilities"
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

export const OrderTypeStandardEquipmentCard: FC<ProductDetailsTabConfigContentProps> = ({ itemKey, urlPathParamsPrefix }) => {
    const toastRef = useRef(useToast())
    const [marketplace, setMarketplace] = useURLSearchParamState(
        `${urlPathParamsPrefix}.selectedMarketplace`,
        PdsMarketplace.DE,
        getEnumConverterGroup(PdsMarketplace).required
    )
    const [languageTag, setLanguageTag] = useURLSearchParamState(`${urlPathParamsPrefix}.selectedLanguage`, "de-DE", stringConverterGroup.required)
    const [standardEquipments, setStandardEquipments] = useState<OrderTypeStandardEquipmentDTO | undefined>(undefined)
    const { addToRequestQueue } = useRequestQueue({ skipRequests: true })

    useEffect(() => {
        const fetchStandardEquipment = async () => {
            setStandardEquipments(undefined)

            try {
                const standardEquipments = await orderTypeApi.details.standardEquipments.getStandardEquipment({
                    ...itemKey,
                    marketplace,
                    languageTag,
                })
                setStandardEquipments(standardEquipments)
            } catch (e) {
                showErrorToast(toastRef.current, e)
            }
        }

        addToRequestQueue(fetchStandardEquipment)
    }, [itemKey, marketplace, languageTag])

    return (
        <OrderTypeStandardEquipmentCardContent
            orderTypeKey={itemKey.key}
            urlPathParamsPrefix={urlPathParamsPrefix}
            marketplace={marketplace}
            setMarketplace={setMarketplace}
            languageTag={languageTag}
            setLanguageTag={setLanguageTag}
            standardEquipments={standardEquipments}
        />
    )
}

export const OrderTypeStandardEquipmentCardContent = ({
    urlPathParamsPrefix,
    orderTypeKey,
    marketplace,
    setMarketplace,
    languageTag,
    setLanguageTag,
    standardEquipments,
}: {
    urlPathParamsPrefix: string
    orderTypeKey: string
    marketplace: PdsMarketplace
    setMarketplace: (marketplace: PdsMarketplace) => void
    languageTag: string
    setLanguageTag: (languageTag: string) => void
    standardEquipments: OrderTypeStandardEquipmentDTO | undefined
}) => {
    const navigate = useNavigate()
    return (
        <WidgetWrapper label={"Standard Equipment"}>
            <SpacedItems direction={"column"} lineSpacing={spacing[32]}>
                <ColumnGroupsWrapper>
                    <MarketSelector
                        marketplace={marketplace}
                        onChange={setMarketplace as (marketplace: PdsMarketplace | undefined) => void}
                        includeGlobal={false}
                    />
                    <MarketLanguageSelector marketplace={marketplace} languageTag={languageTag} onChange={(lt) => !!lt && setLanguageTag(lt)} />
                </ColumnGroupsWrapper>
                <PDivider />

                {standardEquipments === undefined ? (
                    <PSpinner key={"loadingSpinner"} size={"small"} />
                ) : (
                    <Accordion
                        expandAll={false}
                        initiallyExpandedIndex={"all"}
                        urlParamsPrefix={urlPathParamsPrefix}
                        items={[
                            {
                                label: "Standard Equipment Options",
                                children: (
                                    <ContentArea>
                                        {standardEquipments.options.length === 0 ? (
                                            <PText>No standard equipment options available in this market.</PText>
                                        ) : (
                                            <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                                                {standardEquipments.options.map((category) => (
                                                    <>
                                                        <PHeadline variant={"headline-5"}>{category.label}</PHeadline>
                                                        <PDivider color={"neutral-contrast-high"} />
                                                        {category.content.map((item) => (
                                                            <>
                                                                <StyledRow onClick={() => navigate(equipmentRoutes.details(orderTypeKey, item.optionCode))}>
                                                                    <PText>
                                                                        {item.optionCode} {item.name === "" ? "" : ` - ${item.name}`}
                                                                    </PText>
                                                                    {!item.isVisible && (
                                                                        <TagWrapper>
                                                                            <Tag>Not visible</Tag>
                                                                        </TagWrapper>
                                                                    )}
                                                                </StyledRow>
                                                                <PDivider color={"neutral-contrast-low"} />
                                                            </>
                                                        ))}
                                                    </>
                                                ))}
                                            </SpacedItems>
                                        )}
                                    </ContentArea>
                                ),
                            },
                            {
                                label: "Standard Equipment Free Texts",
                                children: (
                                    <ContentArea>
                                        {standardEquipments.freeTexts.length === 0 ? (
                                            <PText>No standard equipment texts available for this market/language combination.</PText>
                                        ) : (
                                            <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                                                {standardEquipments.freeTexts.map((category) => (
                                                    <>
                                                        <PHeadline variant={"headline-5"}>{category.label}</PHeadline>
                                                        <PDivider color={"neutral-contrast-high"} />
                                                        {category.content.map((item) => (
                                                            <>
                                                                <StyledRow>
                                                                    <PText>{item}</PText>
                                                                </StyledRow>
                                                                <PDivider color={"neutral-contrast-low"} />
                                                            </>
                                                        ))}
                                                    </>
                                                ))}
                                            </SpacedItems>
                                        )}
                                    </ContentArea>
                                ),
                            },
                        ]}
                    />
                )}
            </SpacedItems>
        </WidgetWrapper>
    )
}

const StyledRow = styled.div`
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background: ${color.background.surface};
    }
`

const ContentArea = styled.div`
    padding-left: ${spacing[32]};
    padding-right: ${spacing[32]};
    padding-bottom: ${spacing[32]};
`

const TagWrapper = styled.div`
    margin-left: ${spacing[16]};
    margin-right: ${spacing[16]};
`
