import { paintToSampleExteriorColorRoutes } from "../../../../../../common/routes"
import { OrderTypeOptionItemDTOPaintToSampleExteriorColor, PaintToSampleExteriorColorSortField } from "../../../../../../generated/pdsapi"
import { orderTypeApi } from "../../../../../../pdsapi"
import { OrderTypeOptionsCardProps } from "../OrderTypeOptionsCard"
import { getObjectURLParamConverter } from "../../../../../../common/useURLSearchParamState"
import { OrderTypePaintToSampleExteriorColorsCardColumnsConfig } from "./columns/OrderTypePaintToSampleExteriorColorsCardColumns"
import { Text } from "@finder/ui-kit"

type EmptyFilter = Record<string, never>

export const orderTypePaintToSampleExteriorColorsCardConfig: OrderTypeOptionsCardProps<
    OrderTypeOptionItemDTOPaintToSampleExteriorColor,
    EmptyFilter,
    void,
    PaintToSampleExteriorColorSortField
> = {
    label: "PaintToSampleExteriorColor",

    getCreateRoute: paintToSampleExteriorColorRoutes.create,
    getDetailsRoute: paintToSampleExteriorColorRoutes.details,
    getOptions: orderTypeApi.details.options.getPaintToSampleExteriorColors,
    getFilterOptions: () => Promise.resolve(),

    defaultSortField: PaintToSampleExteriorColorSortField.SORT_INDEX,
    sortFieldEnum: PaintToSampleExteriorColorSortField,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<EmptyFilter>({}),

    columnsConfig: OrderTypePaintToSampleExteriorColorsCardColumnsConfig,
    FilterComponent: () => <Text>Filtering Paint to Sample Exterior Colors is currently not supported</Text>,
    filterTagsConfig: () => [],

    isInitiallyExpanded: false,
}
