/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueUpdateDTO,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueUpdateDTOFromJSON,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueUpdateDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueUpdateDTOToJSON,
    RoofColorCustomizationAttributesValueUpdateDTO,
    RoofColorCustomizationAttributesValueUpdateDTOFromJSON,
    RoofColorCustomizationAttributesValueUpdateDTOFromJSONTyped,
    RoofColorCustomizationAttributesValueUpdateDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO
 */
export interface OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {RoofColorCustomizationAttributesValueUpdateDTO}
     * @memberof OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO
     */
    defaultValue: RoofColorCustomizationAttributesValueUpdateDTO;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueUpdateDTO>}
     * @memberof OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueUpdateDTO>;
}

export function OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTOFromJSON(json: any): OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO {
    return OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTOFromJSONTyped(json, false);
}

export function OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'isDefaultValueActive': json['isDefaultValueActive'],
        'defaultValue': RoofColorCustomizationAttributesValueUpdateDTOFromJSON(json['defaultValue']),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueUpdateDTOFromJSON)),
    };
}

export function OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTOToJSON(value?: OldOptionCustomizationAttributesUpdateDTORoofColorCustomizationAttributesValueUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionCode': value.optionCode,
        'modelGenerationKeys': value.modelGenerationKeys,
        'isDefaultValueActive': value.isDefaultValueActive,
        'defaultValue': RoofColorCustomizationAttributesValueUpdateDTOToJSON(value.defaultValue),
        'conditionalValues': ((value.conditionalValues as Array<any>).map(OptionCustomizationConditionalValueDTORoofColorCustomizationAttributesValueUpdateDTOToJSON)),
    };
}


