/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OrderTypeOptionItemDTOExteriorColor,
    OrderTypeOptionItemDTOPaintToSampleExteriorColor,
    OrderTypeOptionItemDTOExteriorColorFromJSONTyped,
    OrderTypeOptionItemDTOExteriorColorToJSON,
    OrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSONTyped,
    OrderTypeOptionItemDTOPaintToSampleExteriorColorToJSON,
} from './';

/**
 * @type OrderTypeOptionItemDTO
 * 
 * @export
 */
export type OrderTypeOptionItemDTO = { type: 'ExteriorColor' } & OrderTypeOptionItemDTOExteriorColor | { type: 'PaintToSampleExteriorColor' } & OrderTypeOptionItemDTOPaintToSampleExteriorColor;

export function OrderTypeOptionItemDTOFromJSON(json: any): OrderTypeOptionItemDTO {
    return OrderTypeOptionItemDTOFromJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeOptionItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'ExteriorColor':
            return {...OrderTypeOptionItemDTOExteriorColorFromJSONTyped(json, true), type: 'ExteriorColor'};
        case 'PaintToSampleExteriorColor':
            return {...OrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSONTyped(json, true), type: 'PaintToSampleExteriorColor'};
        default:
            throw new Error(`No variant of OrderTypeOptionItemDTO exists with 'type=${json['type']}'`);
    }
}

export function OrderTypeOptionItemDTOToJSON(value?: OrderTypeOptionItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'ExteriorColor':
            return OrderTypeOptionItemDTOExteriorColorToJSON(value);
        case 'PaintToSampleExteriorColor':
            return OrderTypeOptionItemDTOPaintToSampleExteriorColorToJSON(value);
        default:
            throw new Error(`No variant of OrderTypeOptionItemDTO exists with 'type=${value['type']}'`);
    }
}

