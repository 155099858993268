import { derivativeRoutes, exteriorColorTagRoutes, modelGenerationRoutes } from "../../../../../../common/routes"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    ExteriorColorType,
    OptionCustomizationAttributesDTOExteriorColor,
    OptionCustomizationAttributesDTOObject,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColor,
    OptionCustomizationAttributesUpdateDTOExteriorColor,
    OptionCustomizationAttributesUpdateDTOObjectOptionCustomization,
} from "../../../../../../generated/pdsapi"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import React, { FC } from "react"
import { propertiesOf, Property } from "../../../../../../common/property"
import { FormDisplayData, getEditData } from "../../../../../../components/formelements/FormDisplayData"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { PButtonPure, PDivider, PHeadline, PText } from "@porsche-design-system/components-react"
import { conditionalValueLabelOf } from "../../../../../../common/conditionalValueLabel"
import { getNumberFormElement } from "../../../../../../components/formelements/numberformelement/NumberFormElement"
import { findOption } from "../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import ObjectID from "bson-objectid"
import { OptionCustomizationType, optionCustomizationTypeOptions } from "../../../optionCustomizationTypes"

export const OptionCustomizationDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<OptionCustomizationAttributesDTOObject, OptionCustomizationAttributesUpdateDTOObjectOptionCustomization>
> = ({ formDisplayData }) => {
    const optionCustomizationProperties = propertiesOf<OptionCustomizationAttributesUpdateDTOObjectOptionCustomization>()
    const [conditionalValues, setConditionalValues] = useSyncState(formDisplayData.data?.conditionalValues ?? [], {
        onChange: (conditionalValues) =>
            getEditData(formDisplayData)?.setValue(
                "conditionalValues",
                conditionalValues.map((conditionalValue) => ({
                    key: conditionalValue.key,
                    modelYearRange: { start: conditionalValue.modelYearRange?.start, end: conditionalValue.modelYearRange?.end },
                    derivativeKeys: conditionalValue.derivativeKeys,
                    value: convertValueToValueUpdate(formDisplayData.data!.type, conditionalValue.value),
                }))
            ),
    })

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Type"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.type}
                    FormElement={getSelectTextFormElement<
                        OptionCustomizationAttributesDTOObject,
                        OptionCustomizationAttributesUpdateDTOObjectOptionCustomization
                    >()}
                    getViewProps={(data) => ({
                        text: optionCustomizationTypeOptions.find((option) => option.value === data.type)?.label ?? data.type,
                    })}
                    getEditProps={(data) => ({
                        value: data.type,
                        options: optionCustomizationTypeOptions,
                        readOnly: true,
                    })}
                />
                <FormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.optionCode}
                    FormElement={getTextFormElement<OptionCustomizationAttributesDTOObject, OptionCustomizationAttributesUpdateDTOObjectOptionCustomization>()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
                <FormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.modelGenerationKeys}
                    FormElement={getTagsFormElement<OptionCustomizationAttributesDTOObject, OptionCustomizationAttributesUpdateDTOObjectOptionCustomization>()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        tags: data.modelGenerations.value.map((modelGeneration) => toTagOption(modelGeneration, modelGenerationRoutes.details)),
                    })}
                    getEditProps={(data) => ({
                        emptyLabel: "-",
                        value: data.modelGenerations.value.map((modelGeneration) => modelGeneration.key),
                        options: data.modelGenerations.options.map(toOption),
                        clearable: true,
                    })}
                />
                <FormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.isDefaultValueActive}
                    FormElement={getBoolFormElement<OptionCustomizationAttributesDTOObject, OptionCustomizationAttributesUpdateDTOObjectOptionCustomization>()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                />
            </RowGroupWrapper>

            <PDivider />
            <>
                <PHeadline variant={"headline-4"}>Default Value</PHeadline>
                {conditionalValues.length ? (
                    <PText color={"neutral-contrast-medium"}>Not applied in: {conditionalValues.map(conditionalValueLabelOf).join(", ")}</PText>
                ) : null}
            </>

            <OptionCustomizationValueContent
                key={"defaultValue"}
                formDisplayData={formDisplayData}
                getValue={(attributes) => attributes.defaultValue}
                property={optionCustomizationProperties.defaultValue}
            />

            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PDivider /> : null}
            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PHeadline variant={"headline-4"}>Conditional Values</PHeadline> : null}

            {conditionalValues.flatMap((conditionalValue, index) => {
                const property = optionCustomizationProperties.conditionalValues._index(index)

                return [
                    <RowGroupWrapper key={`configuration_${index}`} label={"Configuration"}>
                        <FormElementWrapper
                            label={"Key"}
                            formDisplayData={formDisplayData}
                            field={property.key}
                            FormElement={getTextFormElement<
                                OptionCustomizationAttributesDTOObject,
                                OptionCustomizationAttributesUpdateDTOObjectOptionCustomization
                            >()}
                            getViewProps={() => ({
                                value: conditionalValue.key,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.key,
                                readOnly: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`model_year_condition_${index}`} label={"Model Year Condition"}>
                        <FormElementWrapper
                            label={"From"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.start}
                            FormElement={getNumberFormElement<
                                OptionCustomizationAttributesDTOObject,
                                OptionCustomizationAttributesUpdateDTOObjectOptionCustomization
                            >()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange?.start,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange?.start,
                                optional: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"To"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.end}
                            FormElement={getNumberFormElement<
                                OptionCustomizationAttributesDTOObject,
                                OptionCustomizationAttributesUpdateDTOObjectOptionCustomization
                            >()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange?.end,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange?.end,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`derivative_keys_${index}`} label={"Derivative Condition"}>
                        <FormElementWrapper
                            label={"Derivatives"}
                            formDisplayData={formDisplayData}
                            field={property.derivativeKeys}
                            FormElement={getTagsFormElement<
                                OptionCustomizationAttributesDTOObject,
                                OptionCustomizationAttributesUpdateDTOObjectOptionCustomization
                            >()}
                            getViewProps={(attributes) => ({
                                emptyLabel: "-",
                                tags:
                                    conditionalValue.derivativeKeys?.map((derivativeKey) => {
                                        const option = findOption(attributes.derivatives, derivativeKey) ?? {
                                            key: derivativeKey,
                                            label: derivativeKey,
                                        }
                                        return toTagOption(option, () => derivativeRoutes.details(derivativeKey))
                                    }) ?? [],
                            })}
                            getEditProps={(data) => ({
                                emptyLabel: "-",
                                value: conditionalValue.derivativeKeys ?? [],
                                options: data.derivatives.map(toOption),
                                clearable: true,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <OptionCustomizationValueContent
                        key={`customization_value_content_${index}`}
                        formDisplayData={formDisplayData}
                        getValue={() => conditionalValue.value}
                        property={property.value}
                    />,
                    formDisplayData.kind === "EDIT" ? (
                        <PButtonPure
                            key={`delete_conditional_value_${index}`}
                            type={"button"}
                            icon={"delete"}
                            onClick={() => {
                                setConditionalValues(conditionalValues.filter((_, i) => i !== index))
                            }}
                        >
                            Delete Conditional Value
                        </PButtonPure>
                    ) : null,
                    formDisplayData.kind === "EDIT" || index !== conditionalValues.length - 1 ? <PDivider key={`divider_${index}`} /> : null,
                ]
            })}
            {formDisplayData.kind === "EDIT" ? (
                <PButtonPure
                    type={"button"}
                    icon={"add"}
                    onClick={() =>
                        setConditionalValues((conditionalValues) => [
                            ...conditionalValues,
                            {
                                key: ObjectID().toHexString(),
                                modelYearRange: { start: 2020, end: 2020 },
                                value: formDisplayData.data.defaultValue,
                            },
                        ])
                    }
                >
                    Add Conditional Value
                </PButtonPure>
            ) : null}
        </SpacedItems>
    )
}

interface OptionCustomizationValueContentProps<
    ATTRIBUTES extends OptionCustomizationAttributesDTOObject = OptionCustomizationAttributesDTOObject,
    UPDATE extends OptionCustomizationAttributesUpdateDTOObjectOptionCustomization = OptionCustomizationAttributesUpdateDTOObjectOptionCustomization,
> {
    getValue: (attributes: ATTRIBUTES) => ATTRIBUTES["defaultValue"]
    property: Property<UPDATE, UPDATE["defaultValue"]>
    formDisplayData: FormDisplayData<ATTRIBUTES, UPDATE>
}

const OptionCustomizationValueContent: FC<OptionCustomizationValueContentProps> = (props) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <DetailsDefaultLocalizationsFormRow
            label={"Name"}
            formDisplayData={props.formDisplayData}
            field={props.property.name}
            getViewProps={(data) => ({
                value: props.getValue(data).name,
            })}
            getEditProps={(data) => ({
                value: props.getValue(data).name,
                optional: true,
            })}
        />
        <DetailsDefaultLocalizationsFormRow
            label={"Description"}
            formDisplayData={props.formDisplayData}
            field={props.property.description}
            getViewProps={(data) => ({
                value: props.getValue(data).description,
            })}
            getEditProps={(data) => ({
                value: props.getValue(data).description,
                optional: true,
            })}
        />
        <SpecificOptionCustomizationValueContent {...props} />
    </SpacedItems>
)

const SpecificOptionCustomizationValueContent: FC<OptionCustomizationValueContentProps> = (props) => {
    switch (props.formDisplayData.data?.type) {
        case "ExteriorColor": {
            const { getValue, property, formDisplayData } = props as OptionCustomizationValueContentProps<
                OptionCustomizationAttributesDTOExteriorColor,
                OptionCustomizationAttributesUpdateDTOExteriorColor
            >
            return (
                <RowGroupWrapper label={"Color"}>
                    <FormElementWrapper
                        label={"Hex Code"}
                        formDisplayData={formDisplayData}
                        field={property.hexCode}
                        FormElement={getColorPickerFormElement<
                            OptionCustomizationAttributesDTOExteriorColor,
                            OptionCustomizationAttributesUpdateDTOExteriorColor
                        >()}
                        getViewProps={(data) => ({
                            hexValue: getValue(data).hexCode,
                            disableGlossy: true,
                        })}
                        getEditProps={(data) => ({
                            hexValue: getValue(data).hexCode,
                            disableGlossy: true,
                        })}
                    />
                    <FormElementWrapper
                        label={"Exterior Color Tag"}
                        formDisplayData={formDisplayData}
                        field={property.exteriorColorTagKey}
                        FormElement={getTagFormElement<OptionCustomizationAttributesDTOExteriorColor, OptionCustomizationAttributesUpdateDTOExteriorColor>()}
                        getViewProps={(data) => {
                            const tag = getValue(data).tag.value
                            return {
                                tag: tag && toTagOption(tag, exteriorColorTagRoutes.details),
                            }
                        }}
                        getEditProps={(data) => ({
                            value: getValue(data).tag.value?.key ?? "",
                            options: [
                                {
                                    value: "",
                                    label: "-",
                                },
                                ...getValue(data).tag.options.map((tag) => toOption(tag)),
                            ],
                            optional: true,
                        })}
                    />
                    <FormElementWrapper
                        label={"Exterior Color Type"}
                        formDisplayData={formDisplayData}
                        field={property.exteriorColorType}
                        FormElement={getSelectTextFormElement<
                            OptionCustomizationAttributesDTOExteriorColor,
                            OptionCustomizationAttributesUpdateDTOExteriorColor
                        >()}
                        getViewProps={(data) => ({
                            text: getValue(data).exteriorColorType.value?.label ?? "-",
                        })}
                        getEditProps={(data) => ({
                            value: getValue(data).exteriorColorType.value?.key ?? "",
                            options: [
                                {
                                    value: "",
                                    label: "-",
                                },
                                ...getValue(data).exteriorColorType.options.map(toOption),
                            ],
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "PaintToSampleExteriorColor":
        case undefined:
            return null
    }
}

const convertValueToValueUpdate = (
    type: OptionCustomizationType,
    value: OptionCustomizationAttributesDTOObject["defaultValue"]
): OptionCustomizationAttributesUpdateDTOObjectOptionCustomization["defaultValue"] => {
    switch (type) {
        case "ExteriorColor": {
            const exteriorColorValue = value as OptionCustomizationAttributesDTOExteriorColor["defaultValue"]
            return {
                name: exteriorColorValue.name,
                description: exteriorColorValue.description,
                hexCode: exteriorColorValue.hexCode,
                exteriorColorTagKey: exteriorColorValue.tag.value?.key,
                exteriorColorType: exteriorColorValue.exteriorColorType.value?.key ?? ("" as ExteriorColorType),
            } satisfies OptionCustomizationAttributesUpdateDTOExteriorColor["defaultValue"]
        }
        case "PaintToSampleExteriorColor": {
            return {
                name: value.name,
                description: value.description,
            } satisfies OptionCustomizationAttributesDTOPaintToSampleExteriorColor["defaultValue"]
        }
    }
}
