import { DefaultFormElementLoading, FormElement } from "../FormElementWrapper"
import { FieldValues } from "react-hook-form"
import { TagFormElementView, TagFormElementViewProps } from "../tagformelement/TagFormElement"
import { TextFormElementEdit, TextFormElementEditProps } from "../textformelement/TextFormElement"

export const getTagFormTextElement = <DATA, UPDATE extends FieldValues>(): FormElement<
    DATA,
    UPDATE,
    string,
    TagFormElementViewProps,
    TextFormElementEditProps
> => ({
    Loading: DefaultFormElementLoading,
    View: TagFormElementView,
    Edit: TextFormElementEdit,
})
