import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { OptionImage } from "../../../../../components/optionimage/OptionImage"
import { EquipmentItemDTO, EquipmentSortField } from "../../../../../generated/pdsapi"

type EquipmentCatalogueColumnBuilder = ColumnBuilder<EquipmentItemDTO, EquipmentSortField>

export const EquipmentCatalogueColumnsConfig: TableColumnsConfig<EquipmentItemDTO, EquipmentSortField> = () => {
    const imageColumn: EquipmentCatalogueColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (equipmentDetails) => <OptionImage imageKey={equipmentDetails.imageKey} sizes={"100px"} />,
    }

    const orderTypeCodesColumn: EquipmentCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Order Types",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.orderTypeCodes.join(", "),
    }

    const modelYearColumn: EquipmentCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Model Year",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.modelYear.toString(),
    }

    const optionCodeColumn: EquipmentCatalogueColumnBuilder = {
        width: 90,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.optionCode,
    }

    const optionFamilyColumn: EquipmentCatalogueColumnBuilder = {
        width: 100,
        headerCellProps: {
            content: "Option Family",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.family ?? "-",
    }

    const nameColumn: EquipmentCatalogueColumnBuilder = {
        grow: 6,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.name ?? "-",
    }

    const equipmentCategoryColumn: EquipmentCatalogueColumnBuilder = {
        grow: 6,
        headerCellProps: {
            content: "Category",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.categoryName ?? "-",
    }

    const tagsColumn: EquipmentCatalogueColumnBuilder = {
        grow: 6,
        headerCellProps: {
            content: "Tags",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.tagNames.join(", ") ?? "-",
    }

    const modelGenerationColumn: EquipmentCatalogueColumnBuilder = {
        grow: 6,
        headerCellProps: {
            content: "Model Generation",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.modelGenerationName ?? "-",
    }

    return [
        imageColumn,
        orderTypeCodesColumn,
        modelYearColumn,
        optionCodeColumn,
        optionFamilyColumn,
        nameColumn,
        equipmentCategoryColumn,
        tagsColumn,
        modelGenerationColumn,
    ]
}
