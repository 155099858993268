import { interiorRoutes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter, numberConverterGroup, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { InteriorFilterOptionsDTO, InteriorItemDTO, InteriorMaterial, InteriorSortField, SortDirection } from "../../../../generated/pdsapi"
import { interiorApi, InteriorFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { InteriorCatalogueColumnsConfig } from "./columns/InteriorCatalogueColumns"
import { InteriorCatalogueFilter } from "./filter/InteriorCatalogueFilter"
import { InteriorCatalogueFilterTagsConfig } from "./filter/InteriorCatalogueFilterTagsConfig"

export const InteriorCatalogueContainerConfig: ProductListContainerConfig<InteriorItemDTO, InteriorFilter, InteriorFilterOptionsDTO, InteriorSortField> = {
    listName: "Interior Catalogue",
    itemsName: "Interiors",

    buildDetailsPath: (item) => interiorRoutes.details(item.orderTypeKey, item.optionCode),

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<InteriorFilter>({
        modelGenerationKeys: stringConverterGroup.optionalArray,
        modelYears: numberConverterGroup.optionalArray,
        orderTypeKeys: stringConverterGroup.optionalArray,
        materials: getEnumConverterGroup(InteriorMaterial).optionalArray,
        colorTagKeys: stringConverterGroup.optionalArray,
    }),
    defaultSorting: {
        sortField: InteriorSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(InteriorSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: interiorApi.catalogue.getPage,
    fetchFilterOptions: interiorApi.catalogue.getFilterOptions,

    defaultShowsFilter: true,
    Filter: InteriorCatalogueFilter,
    filterTagsConfig: InteriorCatalogueFilterTagsConfig,

    columnsConfig: InteriorCatalogueColumnsConfig,
}

export const InteriorCatalogueContainer = getProductListContainer(InteriorCatalogueContainerConfig)
